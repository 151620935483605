import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DatePicker } from '@mui/lab'
import { FormControlLabel, Grid, Typography } from '@mui/material'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import {
  dateNowIsoString,
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormat,
} from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import {
  CodedConceptDto,
  MedicationControllerService,
  MedicationTerse,
  PersonnelDTO,
} from 'services/openapi'
import {
  MEDICATION_DOSE_FORMS,
  MEDICATION_INFO_SOURCE,
  MEDICATION_LENGTH_TYPES,
  MEDICATION_ROUTE_OF_ADMINISTRATION,
} from 'services/terminologyConstants'
import { selectChartingValidationActive } from 'store/selectors/charting'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import { MedicationTemplateState, TMedicationTemplateValue } from './MedicationTemplate'

type MedicationTemplateComponentProps = MedicationTemplateState & {
  onChange?: (key: string, value: TMedicationTemplateValue) => void
}

const MedicationTemplateComponent = ({
  customIndicator = false,
  dosageNotes,
  internalNotes,
  lastReviewedBy,
  lastReviewedOn,
  medication,
  medicationLength,
  onChange,
  prescriber,
  prn = false,
  reasonForUse,
  route,
  source,
  startDate,
  strengthNum,
  strengthUnit,
}: MedicationTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const userId = useSelector(selectUserId)
  const validationActive = useSelector(selectChartingValidationActive)

  const providers = useProviders()

  const [initialized, setInitialized] = useState(false)
  const [loadingTypeAhead, setLoadingTypeAhead] = useState(false)
  const [searchStr, setSearchStr] = useState('')
  const [medicationOptions, setMedicationOptions] = useState<MedicationTerse[]>([])

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const medicationLengthTypeOpts = useMemo(
    () => terminology.find((term) => term.setName === MEDICATION_LENGTH_TYPES)?.value || [],
    [terminology]
  )
  const medicationDosageFormsOpts = useMemo(
    () => terminology.find((term) => term.setName === MEDICATION_DOSE_FORMS)?.value || [],
    [terminology]
  )
  const medicationRouteOpts = useMemo(
    () =>
      terminology.find((term) => term.setName === MEDICATION_ROUTE_OF_ADMINISTRATION)?.value || [],
    [terminology]
  )
  const medicationSourceOpts = useMemo(
    () => terminology.find((term) => term.setName === MEDICATION_INFO_SOURCE)?.value || [],
    [terminology]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const handleChange = useCallback(
    (key: string, value: TMedicationTemplateValue) => {
      onChange?.(key, value)
    },
    [onChange]
  )

  const handleSearchMedicationOptions = useCallback(
    async (text?: string) => {
      if (text && text.length >= 3) {
        setLoadingTypeAhead(true)
        try {
          const response = await MedicationControllerService.searchMedicationsByName(text)
          setMedicationOptions(response ? response : [])
          const newOptions: CodedConceptDto[] = []
          response?.forEach((obj) => {
            if (medicationOptions.find((opt) => opt.code === obj.code) === undefined)
              newOptions.push(obj)
          })
          if (newOptions.length > 0)
            setMedicationOptions([...new Set([...medicationOptions, ...newOptions])])
          setLoadingTypeAhead(false)
        } catch (error) {
          setMedicationOptions([])
          setLoadingTypeAhead(false)
        }
      } else setMedicationOptions([])
    },
    [medicationOptions]
  )

  const handleMedicationInputChange = useCallback(
    (value: string) => {
      setSearchStr(value)
      handleSearchMedicationOptions(value)
    },
    [handleSearchMedicationOptions]
  )

  useEffect(() => {
    if (!isTemplateEditor) {
      if (lastReviewedBy === undefined && loggedInProvider && loggedInProvider.id)
        handleChange('lastReviewedBy', loggedInProvider.id)
      if (lastReviewedOn === undefined) handleChange('lastReviewedOn', dateNowIsoString())
    }
    setInitialized(true)
  }, [
    handleChange,
    loggedInProvider,
    isTemplateEditor,
    providers,
    userId,
    lastReviewedBy,
    lastReviewedOn,
  ])

  if (!initialized) return null

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Typography component="h1">{t('charting.templates.medication.label')}</Typography>
      </Grid>
      <Grid item md={6} sm={12}>
        <MISAutocomplete
          allowFreeText
          clearOnBlur={false}
          error={validationActive && !medication}
          helperText={
            validationActive && !medication
              ? t('charting.templates.medication.fields.name-required')
              : undefined
          }
          inputValue={medication?.name || searchStr}
          label={t('charting.templates.medication.fields.name')}
          loading={loadingTypeAhead}
          onChange={(value) =>
            onChange?.(
              'medication',
              medicationOptions.find((each) => each.name === value)
            )
          }
          onFocus={() => {
            if (searchStr === '') handleSearchMedicationOptions()
          }}
          onInputChange={handleMedicationInputChange}
          options={medicationOptions.map((each) => each.name as string)}
          required
          value={medication}
        />
      </Grid>
      <Grid item md={3} sm={12}>
        <MISTextField
          label={t('charting.templates.medication.fields.strength')}
          onChange={(e) => handleChange('strengthNum', e.target.value)}
          value={medication?.strength || strengthNum || ''}
        />
      </Grid>
      <Grid item md={3} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.medication.fields.strength-unit')}
          onChange={(e) => handleChange('strengthUnit', e.target.value)}
          options={medicationDosageFormsOpts.map((each) => ({
            label: each.name as string,
            value: each,
          }))}
          value={medication?.strengthUnit || strengthUnit || ''}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.medication.fields.prescriber')}
          onChange={(e) => {
            handleChange('prescriber', e.target.value)
          }}
          options={
            providers?.map((provider: PersonnelDTO) => {
              return {
                label: evaluateLabelUtil(provider?.names),
                value: provider,
              }
            }) || []
          }
          value={providers?.find((each) => each.id === prescriber?.id)}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          helperText={
            validationActive && !source
              ? t('charting.templates.medication.fields.source-required')
              : undefined
          }
          label={t('charting.templates.medication.fields.source')}
          onChange={(e) => handleChange('source', e.target.value)}
          options={medicationSourceOpts.map((each) => ({
            label: each.name as string,
            value: each,
          }))}
          required
          value={source}
        />
      </Grid>
      <Grid item md={4} sm={12}>
        <DatePicker
          inputFormat={IDHE_DATE_DISPLAY_FORMAT}
          label={t('charting.templates.medication.fields.start-date')}
          onChange={(value) => handleChange('startDate', value || '')}
          renderInput={(props) => <MISTextField {...props} />}
          value={startDate || null}
        />
      </Grid>
      <Grid item md={2} sm={12}>
        <FormControlLabel
          control={
            <MISCheckbox checked={!!prn} onChange={(_, checked) => handleChange('prn', checked)} />
          }
          label={t('charting.templates.medication.fields.prn')}
          required
          sx={{ ml: 0 }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MISRadioGroup
          id="medicationLength"
          label={t('charting.templates.medication.fields.medication-length')}
          onChange={(event) => handleChange('medicationLength', event.target.value)}
          options={medicationLengthTypeOpts.map((each) => ({
            label: each.name as string,
            value: each.name,
          }))}
          value={medicationLength || ''}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISSelectDropdown
          label={t('charting.templates.medication.fields.route')}
          onChange={(e) => handleChange('route', e.target.value)}
          options={medicationRouteOpts.map((each) => ({ label: each.name as string, value: each }))}
          value={route}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <MISTextField
          label={t('charting.templates.medication.fields.dosage-notes')}
          multiline
          onChange={(e) => handleChange('dosageNotes', e.target.value)}
          value={dosageNotes || ''}
        />
      </Grid>
      <Grid item md={12} sm={12}>
        <MISTextField
          label={t('charting.templates.medication.fields.reason-for-use')}
          multiline
          onChange={(e) => handleChange('reasonForUse', e.target.value)}
          value={reasonForUse || ''}
        />
      </Grid>
      <Grid item md={12} sm={12}>
        <MISTextField
          label={t('charting.templates.medication.fields.internal-notes')}
          multiline
          onChange={(e) => handleChange('internalNotes', e.target.value)}
          value={internalNotes || ''}
        />
      </Grid>
      {lastReviewedOn && (
        <Grid item sm={12}>
          <Typography>{`${t(
            'charting.templates.allergy.fields.last-updated'
          )}${isoDateToDisplayFormat(lastReviewedOn)}`}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default MedicationTemplateComponent
