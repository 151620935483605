import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
} from '@mui/material'
import { utils } from 'xlsx'
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import MISTable, { Header } from 'common/components/table/MISTable'
import GLOBAL from 'common/styles/global.scss'
import { dateNowIsoString, isoFormatToDisplayFormatNoConvert } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getClientFullName } from 'modules/shared/clientUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { ChartingEntryControllerService, FormSchemaDTO } from 'services/openapi'
import { selectChartingGroupInContext, selectChartingMyTemplates } from 'store/selectors/charting'
import { selectClientDetails } from 'store/selectors/client'
import { selectHistoricalTriggerState } from 'store/selectors/historicalTrigger'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import HistoricalItemFilter from './HistoricalItemFilter'
import {
  getHistoryType,
  getMetadataForTemplate,
  getTemplateDataByMetadataType,
  getTemplateDisplayName,
  getTemplateVersion,
} from './utils'
import { TTemplateData } from '../blots/TemplateBlot'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'
import { TEMPLATE_TYPE } from '../template-management/TemplateEditor'
import { HistoricalFBComponents } from '../templates/form-builder-template/FormBuilderTemplate'

const EXPORT_WORKSHEET_DATA = 'Data'
const EXPORT_WORKSHEET_METADATA = 'Metadata'

type StringArrayMap = {
  [key: string]: string[]
}

type HistoricalItemProps = {
  onSelectTemplate: (value: FormSchemaDTO | null) => void
  preFiltered?: string[]
  preTemplateId?: string
  onSetFilters?: (filters: string[]) => void
}

type TemplateDataWithServiceDateTime = {
  templateData: TTemplateData
  serviceDateTime: string
}

export const HistoricalItem = ({
  onSelectTemplate,
  onSetFilters,
  preFiltered,
  preTemplateId,
}: HistoricalItemProps) => {
  const { handleApiError } = useErrorHandler()
  const providers = useProviders()
  const clientDetails = useSelector(selectClientDetails)
  const myTemplates = useSelector(selectChartingMyTemplates)
  const chartingGroupInContext = useSelector(selectChartingGroupInContext)
  const terminologySets = useSelector(selectTerminology)
  const userId = useSelector(selectUserId)
  const { t } = useTranslation('common')

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [tableValues, setTableValues] = useState<StringArrayMap | null | undefined>(undefined)
  const [tableColumns, setTableColumns] = useState<string[]>([])
  const [currentFilters, setCurrentFilters] = useState<string[]>([])
  const [fullHistoricalData, setFullHistoricalData] = useState<
    TemplateDataWithServiceDateTime[] | undefined
  >(undefined)

  const triggerId = useSelector(selectHistoricalTriggerState)
  const [lastProcessedId, setLastProcessedId] = useState<number | null>(null)

  const tbl = useRef<HTMLTableElement>(null)

  const fetchHistoricalData = useCallback(
    async (templateId: string, filters?: string[]) => {
      if ((clientDetails?.id || chartingGroupInContext?.id) && templateId) {
        let templateDataWithServiceDateTime: TemplateDataWithServiceDateTime[] | undefined =
          undefined
        try {
          const historicalData = await ChartingEntryControllerService.getFilteredEntries(
            templateId,
            clientDetails?.id,
            chartingGroupInContext?.id,
            filters ? filters : undefined,
            Boolean(!myTemplates?.find((template) => template.id === templateId)),
            'AND'
          )
          if (historicalData && historicalData.length > 0)
            templateDataWithServiceDateTime = historicalData.flatMap((each) =>
              each.chartingTemplateBlot.map((blot) => ({
                serviceDateTime:
                  each.serviceDateTime && each.serviceDateTime[0] ? each.serviceDateTime[0] : '',
                templateData: blot.templateData,
              }))
            )
        } catch (error) {
          handleApiError(error)
        }
        if (templateDataWithServiceDateTime && templateDataWithServiceDateTime.length > 0) {
          if (!templateDataWithServiceDateTime[0]?.templateData?.components) {
            setFullHistoricalData(undefined)
            const resultMap: StringArrayMap = {}
            resultMap['Service Date'] = []
            const metadata = getMetadataForTemplate(templateId)
            if (!metadata) return // TODO define type for metadata to always have value
            Object.keys(metadata).forEach((key) => (resultMap[metadata[key].label] = []))
            templateDataWithServiceDateTime.forEach(({ serviceDateTime, templateData }) => {
              Object.keys(resultMap).forEach((label) => {
                const keyForLabel = Object.keys(metadata).find(
                  (key) => metadata[key].label === label
                )
                if (
                  keyForLabel &&
                  (templateData[keyForLabel] || templateData[keyForLabel] === false)
                ) {
                  const value = getTemplateDataByMetadataType(
                    keyForLabel,
                    templateData,
                    metadata,
                    providers
                  )
                  resultMap[label].push(value)
                } else if (label === 'Service Date') {
                  resultMap[label].push(isoFormatToDisplayFormatNoConvert(serviceDateTime))
                } else resultMap[label].push('')
              })
            })
            setTableValues(resultMap)
            //if (!filters || filters.length === 0) {
            setTableColumns(Object.keys(resultMap))
            if (onSetFilters && filters) {
              onSetFilters(filters)
            }
          } else {
            setFullHistoricalData(templateDataWithServiceDateTime)
            const components = templateDataWithServiceDateTime
              .filter((data) => data.templateData && data.templateData.components)
              .map((data) => ({
                components: data.templateData.components,
                'Service Date': isoFormatToDisplayFormatNoConvert(data.serviceDateTime),
              }))
            const resultMap: StringArrayMap = {}
            resultMap['Service Date'] = []
            components.forEach((item) => {
              const componentItem = item.components
              componentItem.forEach((item2) => {
                const { label, value } = item2.data
                if (!resultMap[label as string] && label && label !== 'name') {
                  resultMap[label as string] = []
                }
                //if it is FBCheckbox
                if (item2.data['checked'] !== undefined)
                  resultMap[label as string].push(item2.data['checked'].toString())
                //IF it is FBTerminology
                else if (item2.data['terminologySet']) {
                  if (!value) resultMap[label as string].push('')
                  else {
                    const displayValue = terminologySets
                      .find((x) => x.setName === item2.data['terminologySet'])
                      ?.value.find((y) => y.id === value)?.name
                    resultMap[label as string].push(displayValue || 'INVALID')
                  }
                } else if (item2.name === 'FBTypography') {
                  //if it is .. well thats obvious
                  //skip
                } else if (
                  item2.name === 'FBValueUnit' &&
                  item2.data.unitType &&
                  item2.data.unitValue?.name
                ) {
                  //unit value type, adding the unit to the table as well
                  if (!resultMap['Unit']) {
                    resultMap['Unit'] = []
                  }
                  resultMap['Unit'].push(item2.data.unitValue.name)
                  resultMap[label as string].push(value as string)
                } else resultMap[label as string].push(value as string)
              })
              resultMap['Service Date'].push(item['Service Date'])
            })
            setTableValues(resultMap)
            if (!filters || filters.length === 0 || (resultMap && tableColumns.length === 0)) {
              setTableColumns(Object.keys(resultMap))
            }

            if (filters && filters.length === 0 && onSetFilters) {
              onSetFilters(filters)
            }
          }
        } else {
          setTableValues(null)
          setTableColumns([])
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      clientDetails?.id,
      chartingGroupInContext?.id,
      myTemplates,
      handleApiError,
      onSetFilters,
      providers,
      terminologySets,
    ]
  )

  const getFormattedTemplateOptions = useCallback(() => {
    const mergedTemplates = [
      ...(myTemplates
        ?.filter(
          (template) =>
            template.templateType && template.templateType !== TEMPLATE_TYPE.AGGREGATE_TEMPLATE
        )
        .map((template) => ({
          label: template.name,
          value: template,
        })) || []),
      ...Object.values(TEMPLATES)
        .filter((each) => each.historical === 'table')
        .map((option) => {
          const optionDisplayName = t(TEMPLATES[option?.name]?.key, option?.name)
          return {
            label: optionDisplayName,
            value: option,
          }
        }),
    ]
    return mergedTemplates
  }, [myTemplates, t])

  const handleSelectTemplate = useCallback(
    (value, filters) => {
      fetchHistoricalData(value?.id, filters)
      setSelectedTemplateId(value?.id)
      if (onSelectTemplate) onSelectTemplate(value?.displayName || value || null)
    },
    [fetchHistoricalData, onSelectTemplate]
  )

  const xport = useCallback(
    (fileName: string, password: string) => {
      /* the .current field will be a TABLE element */
      const table_elt = tbl.current
      /* generate SheetJS workbook */
      const wb = utils.table_to_book(table_elt, { raw: true, sheet: EXPORT_WORKSHEET_DATA })
      const metadata = [
        [t('charting.historical.item.template-name'), getTemplateDisplayName(selectedTemplateId)],
        [t('charting.historical.item.template-version'), getTemplateVersion(selectedTemplateId)],
        [t('charting.historical.item.export-date-time'), dateNowIsoString()],
      ]
      const provider = providers?.find((provider) => provider.userId === userId)
      if (provider)
        metadata.push([
          t('charting.historical.item.exported-by'),
          evaluateLabelUtil(provider?.names),
        ])
      if (clientDetails) {
        metadata.push([])
        metadata.push([t('charting.historical.item.client-name'), getClientFullName(clientDetails)])
        metadata.push([
          t('charting.historical.item.client-file-number'),
          (clientDetails.fileNumber as number).toString(),
        ])
      }
      metadata.push([])
      metadata.push([t('charting.historical.item.confidential')])
      const ws = utils.aoa_to_sheet(metadata)
      utils.book_append_sheet(wb, ws, EXPORT_WORKSHEET_METADATA)
      /* export to XLSX */
      XlsxPopulate.fromBlankAsync().then((workbook) => {
        // Modify the workbook.
        Object.keys(wb.Sheets).forEach((sheetName) => {
          const sheet = wb.Sheets[sheetName]
          workbook.addSheet(sheetName)
          Object.keys(sheet).forEach((cell) => {
            const value = wb.Sheets[sheetName][cell]?.v
            if (value) {
              workbook.sheet(sheetName).cell(cell).value(value)
            }
          })
        })
        workbook.deleteSheet('Sheet1')

        // Write to file.
        return workbook.outputAsync({ password }).then(function (blob) {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        })
      })
    },
    [clientDetails, providers, selectedTemplateId, t, userId]
  )

  const handleFiltering = useCallback(
    (filters) => {
      if (selectedTemplateId) {
        setCurrentFilters(filters)
        fetchHistoricalData(selectedTemplateId, filters)
      }
    },
    [fetchHistoricalData, selectedTemplateId]
  )

  const getFiltersForFBTemplate = useCallback(() => {
    if (fullHistoricalData) {
      const components: HistoricalFBComponents = {}
      fullHistoricalData.forEach((each) => {
        if (each.templateData && each.templateData.components) {
          each.templateData.components.forEach((component) => {
            if (
              !component.data?.name ||
              !component.data?.label ||
              !component.name ||
              getHistoryType(component.name) === null
            )
              return

            components[component.data.name] = {
              label: component.data.label as string,
              type: getHistoryType(component.name),
            }
          })
        }
      })
      return components ? components : {}
    }
    return {}
  }, [fullHistoricalData])

  const renderRow = useCallback(
    (_, rowIndex) => {
      if (tableValues) {
        return (
          <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {clientDetails?.fileNumber && (
              <TableCell sx={{ display: 'none' }}>{clientDetails?.fileNumber}</TableCell>
            )}
            {Object.keys(tableValues)
              .filter((key) => tableColumns.includes(key))
              .map((key) => (
                <TableCell
                  key={`${key}-${rowIndex}`}
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {tableValues[key][rowIndex] ?? ''}
                </TableCell>
              ))}
          </TableRow>
        )
      }
    },
    [clientDetails?.fileNumber, tableColumns, tableValues]
  )

  const headCells = useCallback(() => {
    const cells: Header[] = []
    const width = tableColumns.length > 0 ? 100 / (tableColumns.length + 1) : 100
    if (clientDetails?.fileNumber) {
      cells.push({
        displayNone: true,
        id: 'fileNumber',
        label: 'charting.historical.item.client-file-number',
        translated: true,
        width: width + '%',
      })
    }
    tableValues &&
      Object.keys(tableValues).forEach((key) => {
        if (tableColumns.includes(key)) {
          cells.push({
            id: key,
            label: key,
            width: width + '%',
          })
        }
      })
    return cells
  }, [clientDetails?.fileNumber, tableColumns, tableValues])

  const getTableData = useCallback(() => {
    if (tableValues) {
      return Array.from({
        length: Math.max(
          ...Object.entries(tableValues)
            .filter(([key, _]) => tableColumns.includes(key))
            .map(([_, arr]) => arr.length)
        ),
      })
    }
  }, [tableColumns, tableValues])

  useEffect(() => {
    if (triggerId && triggerId !== lastProcessedId && selectedTemplateId) {
      fetchHistoricalData(selectedTemplateId, currentFilters)
      setLastProcessedId(triggerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerId])

  useEffect(() => {
    if (preTemplateId) {
      const template = Object.values(TEMPLATES).find(
        (template) => template.displayName === preTemplateId
      )
      if (template) {
        setCurrentFilters(preFiltered || [])
        handleSelectTemplate(template, preFiltered)
      } else {
        const myTemplate = myTemplates?.find((template) => template.id === preTemplateId)
        if (myTemplate) {
          setCurrentFilters(preFiltered || [])
          handleSelectTemplate(myTemplate, preFiltered)
        }
      }
    }
  }, [handleSelectTemplate, myTemplates, preFiltered, preTemplateId])

  return (
    <Box
      sx={{
        marginLeft: GLOBAL.MARGIN_MD,
      }}
    >
      <Box sx={{ padding: GLOBAL.PADDING_MD }}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <MISAutocomplete
              label={t('charting.historical.item.template')}
              onChange={(value) => handleSelectTemplate(value?.value || null, [])}
              options={getFormattedTemplateOptions()}
            />
          </Grid>
          <Grid item xs={1}>
            {selectedTemplateId && (
              <HistoricalItemFilter
                components={
                  fullHistoricalData ? undefined : getMetadataForTemplate(selectedTemplateId)
                }
                componentsFB={fullHistoricalData ? getFiltersForFBTemplate() : undefined}
                handleFiltering={handleFiltering}
              />
            )}
          </Grid>
          <Grid item xs={11}>
            <MISMultiValueAutocomplete
              allowFreeText={false}
              label={t('charting.historical.item.columns')}
              onChange={(_event: SyntheticEvent, newValue: MultiValueOption[]) =>
                setTableColumns(newValue.map((v) => v.value))
              }
              options={Object.keys(tableValues ?? []).map((v) => ({ label: v, value: v })) || []}
              value={tableColumns.map((v) => ({ label: v, value: v })) || []}
            />
          </Grid>
        </Grid>
        {tableValues != null && (
          <Box sx={{ pt: 3 }}>
            {Object.keys(tableValues).length > 0 && (
              <MISButton
                label={t('charting.historical.item.export')}
                onClick={() => setExportDialogOpen(true)}
                startIcon={<FileDownloadIcon />}
              />
            )}
            <Dialog
              PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault()
                  const formData = new FormData(event.currentTarget)
                  const formJson = Object.fromEntries(formData.entries())
                  const { name, password } = formJson
                  if (name && password) {
                    setExportDialogOpen(false)
                    xport(name as string, password as string)
                  }
                },
              }}
              onClose={() => setExportDialogOpen(false)}
              open={exportDialogOpen}
            >
              <DialogTitle>{t('charting.historical.item.export')}</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                  {t('charting.historical.item.export-text')}
                </DialogContentText>
                <MISTextField
                  fullWidth
                  id="name"
                  label={t('charting.historical.item.file-name')}
                  name="name"
                  required
                  sx={{ mb: 2 }}
                />
                <MISTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          onMouseUp={(e) => e.preventDefault()}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  id="password"
                  label={t('charting.historical.item.file-password')}
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                />
              </DialogContent>
              <DialogActions>
                <MISButton color="secondary" onClick={() => setExportDialogOpen(false)}>
                  {t('common.button.cancel')}
                </MISButton>
                <MISButton color="primary" type="submit">
                  {t('common.button.confirm')}
                </MISButton>
              </DialogActions>
            </Dialog>
            <MISTable
              data={getTableData() ?? []}
              headers={headCells()}
              renderRow={renderRow}
              tableRef={tbl}
            />
          </Box>
        )}
        {tableValues === null && (
          <Box sx={{ marginTop: 3 }}>{t('charting.historical.item.no-data-found')}</Box>
        )}
      </Box>
    </Box>
  )
}
