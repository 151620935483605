import { isoDateToDisplayFormat, isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { getPersonnelFullName } from 'modules/shared/clientUtils'
import { CodedConceptDto, PersonnelDTO, ProgramTerse } from 'services/openapi'
import { ComponentAttachment, ETemplateMetadataType } from '../blots/TemplateBlot'
import AllergyTemplate from '../templates/allergy-template/AllergyTemplate'
import FollowUpTemplate from '../templates/follow-up-template/FollowUpTemplate'
import HeightWeightTemplate from '../templates/height-weight-template/HeightWeightTemplate'
import ImmunizationTemplate from '../templates/immunization-template/ImmunizationTemplate'
import MedicationTemplate from '../templates/medication-template/MedicationTemplate'
import ReferralTemplate from '../templates/referral-template/ReferralTemplate'
import SOAPTemplate from '../templates/soap-template/SOAPTemplate'
import VitalsTemplate from '../templates/vitals-template/VitalsTemplate'

export const getMetadataForTemplate = (templateId: string) => {
  switch (templateId) {
    case 'AllergyTemplate':
      return AllergyTemplate.getMetadata()
    case 'FollowUpTemplate':
      return FollowUpTemplate.getMetadata()
    case 'HeightWeightTemplate':
      return HeightWeightTemplate.getMetadata()
    case 'ImmunizationTemplate':
      return ImmunizationTemplate.getMetadata()
    case 'ReferralTemplate':
      return ReferralTemplate.getMetadata()
    case 'SOAPTemplate':
      return SOAPTemplate.getMetadata()
    case 'VitalsTemplate':
      return VitalsTemplate.getMetadata()
    case 'MedicationTemplate':
      return MedicationTemplate.getMetadata()
    default:
      return undefined
  }
}

export const getHistoryType = (componentName: string) => {
  switch (componentName) {
    case 'FBCheckbox':
      return ETemplateMetadataType.boolean
    case 'FBDatePicker':
      return ETemplateMetadataType.Date
    case 'FBDateTimePicker':
    case 'FBTimePicker':
      return ETemplateMetadataType.DateTime
    case 'FBNumberFormat':
      return ETemplateMetadataType.number
    case 'FBTypography':
      return null
    case 'FBTextField':
    case 'FBValueUnit':
    case 'FBDropdown':
    default:
      return ETemplateMetadataType.string
  }
}

export const getTemplateDataByMetadataType = (key, data, metadata, providers): string => {
  switch (metadata[key].type) {
    case ETemplateMetadataType.CodedConceptDto:
      if (metadata[key].isArray)
        return (data[key] as CodedConceptDto[]).map((each) => each.name).join(', ')
      return (data[key] as CodedConceptDto).name || ''
    case ETemplateMetadataType.ProviderId:
      return getPersonnelFullName(providers?.find((each) => each.id === data[key])) || ''
    case ETemplateMetadataType.PersonnelDTO:
      return getPersonnelFullName(data[key] as PersonnelDTO) || ''
    case ETemplateMetadataType.ProgramTerse:
      if (metadata[key].isArray)
        return (data[key] as ProgramTerse[]).map((each) => each.name).join(', ')
      return (data[key] as ProgramTerse).name || ''
    case ETemplateMetadataType.Date:
      return isoDateToDisplayFormat(data[key])
    case ETemplateMetadataType.DateTime:
      return isoDateToDisplayFormatWithTime(data[key])
    case ETemplateMetadataType.number:
    case ETemplateMetadataType.string:
      return data[key]
    case ETemplateMetadataType.boolean:
      return data[key] ? 'Yes' : 'No'
    case ETemplateMetadataType.ComponentAttachment:
      if (metadata[key].isArray)
        return (data[key] as ComponentAttachment[]).map((each) => each.name).join(', ')
      return (data[key] as ComponentAttachment).name || ''
    default:
      return ''
  }
}

export const getTemplateDisplayName = (templateId: string | undefined) => {
  switch (templateId) {
    case 'AllergyTemplate':
      return AllergyTemplate.getTemplateDisplayName()
    case 'FollowUpTemplate':
      return FollowUpTemplate.getTemplateDisplayName()
    case 'HeightWeightTemplate':
      return HeightWeightTemplate.getTemplateDisplayName()
    case 'ImmunizationTemplate':
      return ImmunizationTemplate.getTemplateDisplayName()
    case 'ReferralTemplate':
      return ReferralTemplate.getTemplateDisplayName()
    case 'SOAPTemplate':
      return SOAPTemplate.getTemplateDisplayName()
    case 'VitalsTemplate':
      return VitalsTemplate.getTemplateDisplayName()
    case 'MedicationTemplate':
      return MedicationTemplate.getTemplateDisplayName()
    default:
      return ''
  }
}

export const getTemplateVersion = (templateId: string | undefined) => {
  switch (templateId) {
    case 'AllergyTemplate':
      return AllergyTemplate.getTemplateVersion()
    case 'FollowUpTemplate':
      return FollowUpTemplate.getTemplateVersion()
    case 'HeightWeightTemplate':
      return HeightWeightTemplate.getTemplateVersion()
    case 'ImmunizationTemplate':
      return ImmunizationTemplate.getTemplateVersion()
    case 'ReferralTemplate':
      return ReferralTemplate.getTemplateVersion()
    case 'SOAPTemplate':
      return SOAPTemplate.getTemplateVersion()
    case 'VitalsTemplate':
      return VitalsTemplate.getTemplateVersion()
    case 'MedicationTemplate':
      return MedicationTemplate.getTemplateVersion()
    default:
      return ''
  }
}
