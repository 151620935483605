import { createRef, SyntheticEvent } from 'react'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import Quill from 'quill'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { ErrorHandlerProvider } from 'core/components/errorhandler/ErrorHandlerContext'
import { store } from 'store'
import TEMPLATES, { NO_ACCESS_TEMPLATE } from '../modules/TemplatesToolbar/Templates'
import AllergyTemplate, {
  AllergyTemplateState,
} from '../templates/allergy-template/AllergyTemplate'
import BodyTemplate, { BodyTemplateState } from '../templates/body-template/BodyTemplate'
import FollowUpTemplate, {
  FollowUpTemplateState,
} from '../templates/follow-up-template/FollowUpTemplate'
import FormBuilderTemplate, {
  FormBuilderTemplateState,
} from '../templates/form-builder-template/FormBuilderTemplate'
import HeightWeightTemplate, {
  HeightWeightTemplateState,
} from '../templates/height-weight-template/HeightWeightTemplate'
import ImageMarkerTemplate, {
  ImageMarkerTemplateState,
} from '../templates/image-marker-template/ImageMarkerTemplate'
import ImageTemplate, { ImageTemplateState } from '../templates/image-template/ImageTemplate'
import ImmunizationTemplate, {
  ImmunizationTemplateState,
} from '../templates/immunization-template/ImmunizationTemplate'
import MedicationTemplate, {
  MedicationTemplateState,
} from '../templates/medication-template/MedicationTemplate'
import NoAccessTemplate, {
  NoAccessTemplateState,
} from '../templates/no-access-template/NoAccessTemplate'
import ReferralTemplate, {
  ReferralTemplateState,
} from '../templates/referral-template/ReferralTemplate'
import SOAPTemplate, { SOAPTemplateState } from '../templates/soap-template/SOAPTemplate'
import VitalsTemplate, { VitalsTemplateState } from '../templates/vitals-template/VitalsTemplate'

const BlockEmbed = Quill.import('blots/block/embed')

export enum ETemplateMetadataType {
  boolean = 'boolean',
  number = 'number',
  string = 'string',
  ComponentAttachment = 'ComponentAttachment',
  CodedConceptDto = 'CodedConceptDto',
  Date = 'Date',
  DateTime = 'DateTime',
  PersonnelDTO = 'PersonnelDTO',
  ProgramTerse = 'ProgramTerse',
  ProviderId = 'ProviderId',
}

export interface ITemplateMetadataBase {
  createdBy: { label: string; type: ETemplateMetadataType }
  createdOn: { label: string; type: ETemplateMetadataType }
}

export interface ITemplateMetadata extends ITemplateMetadataBase {
  [key: string]: {
    isArray?: boolean
    label: string
    required?: boolean
    type: ETemplateMetadataType
  }
}

export type ComponentAttachment = {
  file?: File
  fileId?: string
  fileSize: number
  name: string
}

export type TTemplateData = AllergyTemplateState &
  BodyTemplateState &
  FollowUpTemplateState &
  FormBuilderTemplateState &
  HeightWeightTemplateState &
  ImageMarkerTemplateState &
  ImageTemplateState &
  ImmunizationTemplateState &
  NoAccessTemplateState &
  ReferralTemplateState &
  SOAPTemplateState &
  VitalsTemplateState &
  MedicationTemplateState

export type TTemplateBlot = {
  formBuilderId?: string
  isFormBuilder?: boolean
  templateData: TTemplateData
  templateId: string
  templateName: string
  templateRecordId: string
}

export interface ITemplate {
  type: string
  getData: () =>
    | AllergyTemplateState
    | BodyTemplateState
    | FollowUpTemplateState
    | FormBuilderTemplateState
    | HeightWeightTemplateState
    | ImageMarkerTemplateState
    | ImageTemplateState
    | ImmunizationTemplateState
    | NoAccessTemplateState
    | ReferralTemplateState
    | SOAPTemplateState
    | VitalsTemplateState
    | MedicationTemplateState

  getEmptyData: () =>
    | AllergyTemplateState
    | BodyTemplateState
    | FollowUpTemplateState
    | FormBuilderTemplateState
    | HeightWeightTemplateState
    | ImageMarkerTemplateState
    | ImageTemplateState
    | ImmunizationTemplateState
    | NoAccessTemplateState
    | ReferralTemplateState
    | SOAPTemplateState
    | VitalsTemplateState
    | MedicationTemplateState

  save: () => Promise<void>
  cancel: () => Promise<void>
}

class TemplateBlot extends BlockEmbed {
  static blotName = 'template-blot'
  static tagName = 'div'
  static className = 'template-blot'
  static data: TTemplateData
  static refs: { [key: string]: React.RefObject<ITemplate> } = {}

  static create(templateBlot: TTemplateBlot) {
    const {
      formBuilderId,
      isFormBuilder,
      templateData,
      templateId,
      templateName,
      templateRecordId,
    } = templateBlot
    const id = uuidv4()
    const node = super.create(true)
    node.setAttribute('data-id', id)
    node.setAttribute('is-form-builder', isFormBuilder)
    if (!formBuilderId && templateId === TEMPLATES.FormBuilderTemplate.name)
      node.setAttribute('form-builder-id', id)
    else node.setAttribute('form-builder-id', formBuilderId)
    node.setAttribute('template-name', templateName)
    node.setAttribute(
      'template-id',
      templateId === TEMPLATES.FormBuilderTemplate.name ? id : templateId
    )
    node.setAttribute('template-record-id', templateRecordId || '')
    node.setAttribute('contenteditable', false)
    node.addEventListener('paste', (event: SyntheticEvent) => event.stopPropagation())
    const templateRef = createRef<never>()
    TemplateBlot.refs = { ...TemplateBlot.refs, [id]: templateRef }
    const rootNode = createRoot(node)
    let TemplateComponent
    if (templateName === NO_ACCESS_TEMPLATE.name) {
      TemplateComponent = NO_ACCESS_TEMPLATE.template as typeof NoAccessTemplate
      if (templateData && templateData.added) node.classList.add('protected-content')
    } else if (templateName === TEMPLATES.AllergyTemplate.name)
      TemplateComponent = TEMPLATES.AllergyTemplate.template as typeof AllergyTemplate
    else if (templateName === TEMPLATES.BodyTemplate.name)
      TemplateComponent = TEMPLATES.BodyTemplate.template as typeof BodyTemplate
    else if (templateName === TEMPLATES.FollowUpTemplate.name)
      TemplateComponent = TEMPLATES.FollowUpTemplate.template as typeof FollowUpTemplate
    else if (templateName === TEMPLATES.FormBuilderTemplate.name || isFormBuilder)
      TemplateComponent = TEMPLATES.FormBuilderTemplate.template as typeof FormBuilderTemplate
    else if (templateName === TEMPLATES.HeightWeightTemplate.name)
      TemplateComponent = TEMPLATES.HeightWeightTemplate.template as typeof HeightWeightTemplate
    else if (templateName === TEMPLATES.ImageMarkerTemplate.name)
      TemplateComponent = TEMPLATES.ImageMarkerTemplate.template as typeof ImageMarkerTemplate
    else if (templateName === TEMPLATES.ImageTemplate.name)
      TemplateComponent = TEMPLATES.ImageTemplate.template as typeof ImageTemplate
    else if (templateName === TEMPLATES.ImmunizationTemplate.name)
      TemplateComponent = TEMPLATES.ImmunizationTemplate.template as typeof ImmunizationTemplate
    else if (templateName === TEMPLATES.ReferralTemplate.name)
      TemplateComponent = TEMPLATES.ReferralTemplate.template as typeof ReferralTemplate
    else if (templateName === TEMPLATES.SOAPTemplate.name)
      TemplateComponent = TEMPLATES.SOAPTemplate.template as typeof SOAPTemplate
    else if (templateName === TEMPLATES.VitalsTemplate.name)
      TemplateComponent = TEMPLATES.VitalsTemplate.template as typeof VitalsTemplate
    else if (templateName === TEMPLATES.MedicationTemplate.name)
      TemplateComponent = TEMPLATES.MedicationTemplate.template as typeof MedicationTemplate
    if (TemplateComponent)
      rootNode.render(
        <ErrorHandlerProvider>
          <RecoilRoot>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <TemplateComponent data={templateData} ref={templateRef} />
              </LocalizationProvider>
            </Provider>
          </RecoilRoot>
        </ErrorHandlerProvider>
      )
    return node
  }

  static value(domNode: Element) {
    const id = domNode.getAttribute('data-id') as string
    const formBuilderId = domNode.getAttribute('form-builder-id')
    const isFormBuilder = domNode.getAttribute('is-form-builder') as string
    const templateId = domNode.getAttribute('template-id') as string
    const templateName = domNode.getAttribute('template-name') as string
    const templateRecordId = domNode.getAttribute('template-record-id') as string
    const ref = TemplateBlot.refs[id]
    const data = ref &&
      ref.current && {
        blotId: id,
        formBuilderId: formBuilderId ? formBuilderId : undefined,
        isFormBuilder:
          isFormBuilder === 'true' || templateName === TEMPLATES.FormBuilderTemplate.name,
        templateData: ref.current.getData(),
        templateId,
        templateName,
        templateRecordId,
      }
    return data
  }

  static async saveAllTemplates(ids: string[]) {
    const savePromises = ids.map((id) => {
      return TemplateBlot.refs[id].current?.save()
    })

    const results = await Promise.allSettled(savePromises)
    const hasFailure = results.some((result) => result.status === 'rejected')

    if (hasFailure) {
      const cancelPromises = ids.map((id) => {
        return TemplateBlot.refs[id].current?.cancel()
      })

      await Promise.allSettled(cancelPromises)
      return Promise.reject('Error saving templates')
    }
    return Promise.resolve()
  }

  static getEmptyDataForBlot(id: string) {
    return TemplateBlot.refs[id].current?.getEmptyData()
  }

  constructor(domNode: Element) {
    super(domNode)
    this.id = domNode.getAttribute('data-id') as string
    this.data = TemplateBlot.data
  }
}

export default TemplateBlot
