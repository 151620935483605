import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, Stack, TableCell, TableRow } from '@mui/material'
import MISChip from 'common/components/form/MISChip'
import MISTextField from 'common/components/form/MISTextField'
import Loader from 'common/components/Loader'
import MISTable, { PaginationProps } from 'common/components/table/MISTable'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { FormSchemaControllerService, PageFormSchemaLightDTO } from 'services/openapi'
import { setCurrentTemplate } from 'store/reducers/charting'
import { TEMPLATE_TYPE } from './TemplateEditor'

const TemplateTables = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()

  const [searchStr, setSearchStr] = useState('')
  const [templatesResp, setTemplatesResp] = useState<PageFormSchemaLightDTO>()

  const [pagination, setPagination] = useState<PaginationProps>({
    order: 'asc',
    orderBy: '',
    page: 0,
    rowsPerPage: 10,
  })

  const handleEditTemplate = useCallback(
    async (template) => {
      const formSchemaDTO = await FormSchemaControllerService.getFormSchema(template.id)
      dispatch(setCurrentTemplate(formSchemaDTO))
      navigate('/admin/template-editor')
    },
    [dispatch, navigate]
  )

  const handleSearchStrChange = useCallback(
    (e) => {
      setPagination({ ...pagination, page: 0 })
      setSearchStr(e.target.value)
    },
    [pagination]
  )

  const renderRow = useCallback(
    (template, index) => {
      return (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ minWidth: '120px' }}>{template.name}</TableCell>
          <TableCell sx={{ minWidth: '120px' }}>{template.description}</TableCell>
          <TableCell sx={{ minWidth: '120px' }}>
            {template.designation && template.designation.length > 0
              ? template.designation.charAt(0).toUpperCase() +
                template.designation.slice(1).toLowerCase()
              : ''}
          </TableCell>
          <TableCell sx={{ minWidth: '120px' }}>
            {template.templateType === TEMPLATE_TYPE.USER_DEFINED_BASE_TEMPLATE
              ? t('charting.template-management.base')
              : t('charting.template-management.aggregate')}
          </TableCell>
          <TableCell sx={{ minWidth: '120px' }}>
            {template.tags?.split(' ').map((tag, index) => (
              <MISChip key={index} label={tag} sx={{ mr: 0.5 }} />
            ))}
          </TableCell>
          <TableCell sx={{ minWidth: '120px' }}>
            {template.state && template.state.length > 0
              ? template.state.charAt(0).toUpperCase() + template.state.slice(1).toLowerCase()
              : ''}
          </TableCell>
          <TableCell sx={{ minWidth: '60px' }}>
            <IconButton onClick={() => handleEditTemplate(template)}>
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    },
    [handleEditTemplate, t]
  )

  const headerCells = [
    { id: 'name', label: 'charting.template-management.name', translated: true, width: '19%' },
    {
      id: 'description',
      label: 'charting.template-editor.description',
      translated: true,
      width: '19%',
    },
    {
      id: 'designation',
      label: 'charting.template-management.private-public',
      translated: true,
      width: '10%',
    },
    {
      id: 'templateType',
      label: 'charting.template-management.type',
      translated: true,
      width: '10%',
    },
    { id: 'tags', label: 'charting.template-editor.tags', translated: true, width: '14%' },
    { id: 'state', label: 'charting.template-management.state', translated: true, width: '14%' },
    { id: 'actions', label: '', width: '14%' },
  ]

  const aboveTable = useCallback(() => {
    return (
      <Stack direction="row-reverse" sx={{ mt: 2, width: '100%' }}>
        <MISTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label={t('common.button.search')}
          onChange={handleSearchStrChange}
          sx={{ pr: 2 }}
          value={searchStr}
        />
      </Stack>
    )
  }, [handleSearchStrChange, searchStr, t])

  useEffect(() => {
    FormSchemaControllerService.searchFormSchema(
      searchStr === '' ? '%' : `%${searchStr}%`,
      undefined,
      true,
      undefined,
      false,
      true,
      undefined,
      undefined,
      undefined,
      false,
      pagination.page,
      pagination.rowsPerPage
    )
      .then((resp) => {
        setTemplatesResp(resp)
      })
      .catch((error) => handleApiError(error))
  }, [handleApiError, pagination.page, pagination.rowsPerPage, searchStr])

  if (!templatesResp) return <Loader />

  return (
    <MISTable
      aboveTable={aboveTable()}
      data={templatesResp.content ?? []}
      headers={headerCells}
      renderRow={renderRow}
      totalElements={templatesResp.totalElements ?? 0}
      updatePagination={setPagination}
    />
  )
}

export default TemplateTables
