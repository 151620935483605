import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import MISChip from 'common/components/form/MISChip'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { ErrorType, getError } from 'modules/shared/utils'
import { DataDomain, FormSchemaDTO } from 'services/openapi'
import { setCurrentTemplate } from 'store/reducers/charting'
import { TEMPLATE_TYPE } from './TemplateEditor'

export type TemplateEditorMetadataProps = {
  currTemplate: FormSchemaDTO | undefined
  dataDomains: DataDomain[] | undefined
  fromPopup?: boolean
  errors?: ErrorType[]
}

const TemplateEditorMetadata = ({
  currTemplate,
  dataDomains,
  errors,
  fromPopup,
}: TemplateEditorMetadataProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (currTemplate) {
      console.log('currTemplate', currTemplate)
    }
  }, [currTemplate])

  return (
    <Grid container spacing={2} sx={{ display: 'flex', p: fromPopup ? 0 : 3 }}>
      <Grid item xs={fromPopup ? 9 : 4}>
        <MISTextField
          disabled={!!currTemplate?.id}
          error={errors && !!getError(errors, 'templateName')}
          helperText={errors && getError(errors, 'templateName')}
          id="template-name"
          label={t('charting.template-editor.template-name')}
          onChange={(e) => dispatch(setCurrentTemplate({ ...currTemplate, name: e.target.value }))}
          required
          value={currTemplate?.name || ''}
        />
      </Grid>
      {!fromPopup && (
        <Grid item xs={4}>
          {dataDomains && (
            <MISSelectDropdown
              disabled={
                !!currTemplate?.id ||
                currTemplate?.templateType === TEMPLATE_TYPE.AGGREGATE_TEMPLATE
              }
              id="data-domain"
              label={t('charting.template-editor.data-domain')}
              onChange={(e) =>
                dispatch(setCurrentTemplate({ ...currTemplate, dataDomainId: e.target.value }))
              }
              options={dataDomains.map((domain) => ({
                label: domain.name as string,
                value: domain.id,
              }))}
              readOnly={
                !!currTemplate?.id ||
                currTemplate?.templateType === TEMPLATE_TYPE.AGGREGATE_TEMPLATE
              }
              required
              value={
                currTemplate?.templateType === TEMPLATE_TYPE.AGGREGATE_TEMPLATE
                  ? undefined
                  : currTemplate?.dataDomainId
              }
            />
          )}
        </Grid>
      )}
      <Grid alignContent="center" item xs={2}>
        <ToggleButtonGroup
          aria-label="template designation"
          color="primary"
          exclusive
          onChange={(_, value) =>
            dispatch(setCurrentTemplate({ ...currTemplate, designation: value }))
          }
          size="small"
          value={currTemplate?.designation || t('charting.template-editor.private')}
        >
          <ToggleButton aria-label="private" value={t('charting.template-editor.private')}>
            {t('charting.template-editor.private')}
          </ToggleButton>
          <ToggleButton aria-label="public" value={t('charting.template-editor.public')}>
            {t('charting.template-editor.public')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {!fromPopup && (
        <Grid alignContent="center" item xs={2}>
          <MISChip
            color={
              currTemplate?.state === t('charting.template-editor.published')
                ? 'primary'
                : 'default'
            }
            label={currTemplate?.state || t('charting.template-editor.draft')}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <MISTextField
          id="description"
          label={t('charting.template-editor.description')}
          multiline
          onChange={(e) =>
            dispatch(setCurrentTemplate({ ...currTemplate, description: e.target.value }))
          }
          value={currTemplate?.description || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <MISTextField
          error={errors && !!getError(errors, 'templateTags')}
          helperText={errors && getError(errors, 'templateTags')}
          id="tags"
          label={t('charting.template-editor.tags')}
          onChange={(e) => dispatch(setCurrentTemplate({ ...currTemplate, tags: e.target.value }))}
          required
          value={currTemplate?.tags || ''}
        />
      </Grid>
    </Grid>
  )
}
export default TemplateEditorMetadata
