import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Check, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useRecoilState } from 'recoil'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISRadio from 'common/components/form/MISRadio'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import MISTable from 'common/components/table/MISTable'
import GLOBAL from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { isDirtyState } from 'recoil/isDirty'
import {
  BusinessLineTerse,
  DataDomainAndEntityPermissionsDTO,
  RoleControllerService,
  RoleView,
  UserBusinessLineControllerService,
  UserBusinessLineDTO,
  UserControllerService,
  UserProvisionDTO,
  UserProvisionLightDTO,
  UserRoleControllerService,
  UserRoleDTO,
} from 'services/openapi'
import { selectBusinessLinesTerse } from 'store/selectors/businessLine'
import { selectBusinessLines } from 'store/selectors/permission'

const steps = ['User Profile', 'Role Assignment', 'Business Line Assignment', 'Summary', 'Success']
const provider_steps = ['Role Assignment', 'Business Line Assignment', 'Summary']

const YesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

type UserDetails = {
  firstName: string | undefined
  lastName: string | undefined
  jobTitle: string | undefined
  email: string | undefined
  password: string | undefined
}

type ErrorObject = {
  field: string
  message: string
}

interface CustomStepIconRootProps {
  ownerState: {
    active?: boolean
    completed?: boolean
  }
}

type UserProvisioningProps = {
  flipPermissions?: (flip: boolean) => void
  userId?: string
  provUserFirstName?: string
  provUserLastName?: string
}

type PersistedUserData = {
  userRoles?: UserRoleDTO[]
  userBusinessLines?: UserBusinessLineDTO[]
}

const CustomStepIconRoot = styled('div')<CustomStepIconRootProps>(({ ownerState, theme }) => ({
  alignItems: 'center',
  backgroundColor: ownerState.active || ownerState.completed ? GLOBAL.LINK_PRIMARY_COLOR : '#ccc',
  borderRadius: '50%',
  color: '#fff',
  display: 'flex',
  height: 25,
  justifyContent: 'center',
  width: 25,
  zIndex: 1,
}))

function CustomStepIcon(props: StepIconProps) {
  const { active, className, completed } = props

  return (
    <CustomStepIconRoot className={className} ownerState={{ active, completed }}>
      {completed ? <Check /> : props.icon}
    </CustomStepIconRoot>
  )
}

const UserProvisioning = forwardRef(
  (
    { flipPermissions, provUserFirstName, provUserLastName, userId }: UserProvisioningProps,
    ref
  ) => {
    const { buildError, getError, handleApiError, hasError, isError, setErrorList } =
      useErrorHandler()
    const { t } = useTranslation('common')
    const { showSnackSuccess } = useSnack()
    const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
    const [activeStep, setActiveStep] = useState(0)
    const [userDetails, setUserDetails] = useState<UserDetails>({
      email: undefined,
      firstName: provUserFirstName || undefined,
      jobTitle: undefined,
      lastName: provUserLastName || undefined,
      password: undefined,
    })
    const [isProviderEditing, setIsProviderEditing] = useState<boolean>(false)
    const [allRoles, setAllRoles] = useState<RoleView[]>([])
    const [showPassword, setShowPassword] = useState(false)
    const allBusinessLines = useSelector(selectBusinessLinesTerse)
    const allBusinessLinesWithPrograms = useSelector(selectBusinessLines)
    const [selectedBusinessLines, setSelectedBusinessLines] = useState<BusinessLineTerse[]>([])
    const [selectedRole, setSelectedRole] = useState<RoleView | undefined>(undefined)
    const [managementRole, setManagementRole] = useState<RoleView | undefined>(undefined)
    const [isManager, setIsManager] = useState<boolean>(false)
    const [persistedData, setPersistedData] = useState<PersistedUserData | undefined>(undefined)
    const [permissionModel, setPermissionModel] = useState<
      DataDomainAndEntityPermissionsDTO[] | undefined
    >(undefined)
    const selectedSteps = useMemo(() => {
      return userId ? provider_steps : steps
    }, [userId])

    const setProviderEditing = useCallback((isEditing: boolean) => {
      setIsProviderEditing(isEditing)
      setActiveStep(1)
    }, [])

    useImperativeHandle(ref, () => ({
      setProviderEditing,
    }))

    const handleUserDetailChange = useCallback(
      (value: string, field: string) => {
        if (!isDirty) setIsDirty(true)
        if (field)
          setUserDetails((prevUserDetails) => {
            const updatedUserDetails: UserDetails = {
              ...prevUserDetails,
              [field]: value,
            }
            return updatedUserDetails
          })
      },
      [isDirty, setIsDirty]
    )

    const validate = useCallback(() => {
      const errors: ErrorObject[] = []
      if (activeStep === 0) {
        if (!userDetails?.firstName) {
          errors.push(
            buildError('userDetails.firstName', 'api.error.required-field', {
              fieldName: t('user-provisioning.userDetails.first-name'),
            })
          )
        }
        if (!userDetails?.lastName) {
          errors.push(
            buildError('userDetails.lastName', 'api.error.required-field', {
              fieldName: t('user-provisioning.userDetails.last-name'),
            })
          )
        }
        if (!userDetails?.email) {
          errors.push(
            buildError('userDetails.email', 'api.error.required-field', {
              fieldName: t('user-provisioning.userDetails.email'),
            })
          )
        } else {
          const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
            userDetails?.email
          )
          if (!isValidEmail) {
            errors.push(
              buildError('userDetails.email', 'api.error.invalid-email', {
                fieldName: t('user-provisioning.userDetails.email'),
              })
            )
          }
        }
        if (!userDetails?.password) {
          errors.push(
            buildError('userDetails.password', 'api.error.required-field', {
              fieldName: t('user-provisioning.userDetails.password'),
            })
          )
        }
      }
      setErrorList(errors)
      return errors.length === 0
    }, [activeStep, buildError, setErrorList, t, userDetails])

    const transformAssociatedBusinessLines = useCallback(
      (businessLine) => {
        return allBusinessLinesWithPrograms
          ?.filter((b) => businessLine.id === b.id)
          .flatMap((ubl) => ubl.programs)
          .map((r) => ({ id: r?.id || '', name: r?.name || '' }))
          .filter((entry, index, array) => array.findIndex((val) => val.id === entry.id) === index)
          .map((r) => r?.name || '')
          .join(', ')
      },
      [allBusinessLinesWithPrograms]
    )

    const handleNewUserButtonClick = useCallback(() => {
      setActiveStep(0)
      setUserDetails({
        email: undefined,
        firstName: undefined,
        jobTitle: undefined,
        lastName: undefined,
        password: undefined,
      })
      setSelectedRole(undefined)
      setIsManager(false)
      setSelectedBusinessLines([])
      setPermissionModel(undefined)
    }, [])

    const mergeWithPersistedData = useCallback(() => {
      const result: UserProvisionLightDTO = {}
      const bls: UserBusinessLineDTO[] = []
      selectedBusinessLines.forEach((selectedBusinessLine) => {
        const persistedBusinessLine = persistedData?.userBusinessLines?.find(
          (ubl) => ubl.id === selectedBusinessLine.id
        )
        if (persistedBusinessLine) {
          bls.push({ businessLine: persistedBusinessLine })
        } else {
          bls.push({
            businessLine: {
              id: selectedBusinessLine.id,
              name: selectedBusinessLine.name,
            },
          })
        }
      })
      result.userBusinessLines = bls

      const nowRole = persistedData?.userRoles?.find(
        (role) => role.role?.name === selectedRole?.name
      )
      if (nowRole) {
        result.userRoles = [nowRole]
      } else {
        result.userRoles = [
          {
            role: { id: selectedRole?.id, name: selectedRole?.name },
          },
        ]
      }
      if (isManager) {
        const persistedManagerRole = persistedData?.userRoles?.find(
          (role) => role.role?.name === 'Management'
        )
        if (persistedManagerRole) {
          result.userRoles.push(persistedManagerRole)
        } else {
          if (managementRole) {
            result.userRoles.push({ role: { id: managementRole.id, name: managementRole.name } })
          }
        }
      }
      return result
    }, [isManager, managementRole, persistedData, selectedBusinessLines, selectedRole])

    const getPreviewWithUserId = useCallback(
      async (userId: string) => {
        const userRoles = await UserRoleControllerService.listUserRoles(userId, true)
        if (userRoles) {
          const roles = userRoles.content?.map((role) => role.role?.name) as string[]
          const userPreProfile = await UserRoleControllerService.getDataDomainPermissionsPreview(
            roles
          )
          const userDataProfile = await UserControllerService.getById(userId)
          setUserDetails((prevUserDetails) => {
            const updatedUserDetails: UserDetails = {
              ...prevUserDetails,
              email: userDataProfile?.emailAddress,
              jobTitle: userDataProfile?.jobTitle,
            }
            return updatedUserDetails
          })
          setPermissionModel(userPreProfile)
          if (roles.indexOf('Management') > -1) {
            setIsManager(true)
          }
          const roleViews = userRoles.content?.filter(
            (role) => role.role?.name !== 'SYSTEM' && role.role?.name !== 'Management'
          )
          const userBusinessLines = await UserBusinessLineControllerService.listUserBusinessLines(
            userId
          )
          if (userBusinessLines) {
            setPersistedData({
              userBusinessLines: userBusinessLines.content,
              userRoles: userRoles.content,
            })
            const businessLinesTerse = allBusinessLines?.filter((bl) =>
              userBusinessLines.content?.some((ubl) => ubl.id === bl.id)
            )
            setSelectedBusinessLines(businessLinesTerse || [])
          }
          setSelectedRole(roleViews?.[0]?.role)
          setActiveStep(3)
        }
      },
      [allBusinessLines]
    )

    const handleNextButtonClick = useCallback(async () => {
      if (validate()) {
        let progress = true
        if (activeStep === 2) {
          const selectedRoles = selectedRole ? [selectedRole] : []
          if (isManager && managementRole) {
            selectedRoles.push(managementRole)
          }
          const userPreProfile = await UserRoleControllerService.getDataDomainPermissionsPreview(
            selectedRoles.map((role) => role.name) as string[]
          )
          setPermissionModel(userPreProfile)
        } else if (activeStep === 3 && (!userId || isProviderEditing)) {
          try {
            if (!userId) {
              const userProvisionDTO: UserProvisionDTO = {
                businessLines: selectedBusinessLines.map((b) => b.id || '') || undefined,
                email: userDetails.email || '',
                firstName: userDetails.firstName || '',
                isAdmin: false,
                jobTitle: userDetails.jobTitle || '',
                lastName: userDetails.lastName || '',
                password: userDetails.password || '',
                roles: selectedRole ? [selectedRole.id || ''] : undefined,
                username: userDetails.email || '',
              }
              await UserControllerService.provisionUser(userProvisionDTO, ['ALL'])
              showSnackSuccess(t('api.save-success'))
            } else {
              const merged = mergeWithPersistedData()
              await UserControllerService.updateProvisionUser(userId, merged)
              showSnackSuccess(t('api.save-success'))
            }
          } catch (error) {
            handleApiError(error)
            progress = false
          }
        }
        if (progress) {
          if (activeStep === 3 && userId) {
            getPreviewWithUserId(userId)
            flipPermissions && flipPermissions(false)
            setIsProviderEditing(false)
          } else {
            setActiveStep((prev) => prev + 1)
          }
        }
      }
    }, [
      activeStep,
      flipPermissions,
      getPreviewWithUserId,
      handleApiError,
      isManager,
      isProviderEditing,
      managementRole,
      mergeWithPersistedData,
      selectedBusinessLines,
      selectedRole,
      showSnackSuccess,
      t,
      userDetails,
      userId,
      validate,
    ])

    const handleBackButtonClick = useCallback(() => {
      setActiveStep((prev) => prev - 1)
    }, [])

    const getAllRoles = useCallback(async () => {
      const userRoles = await RoleControllerService.searchRolesPaged()
      if (userRoles?.content) {
        const roles = userRoles.content.filter(
          (role) => role.name !== 'SYSTEM' && role.name !== 'Management'
        )
        const managementRole = userRoles.content.find((role) => role.name === 'Management')
        setManagementRole(managementRole)
        setAllRoles(roles)
      }
    }, [])

    const handleSelectBusinessLine = useCallback((businessLine: BusinessLineTerse) => {
      setSelectedBusinessLines((prevSelectedBusinessLines) => {
        const updatedSelectedBusinessLines = [...prevSelectedBusinessLines]
        if (
          prevSelectedBusinessLines.some(
            (selectedBusinessLine) => selectedBusinessLine.id === businessLine.id
          )
        ) {
          updatedSelectedBusinessLines.splice(
            prevSelectedBusinessLines.findIndex(
              (selectedBusinessLine) => selectedBusinessLine.id === businessLine.id
            ),
            1
          )
        } else {
          updatedSelectedBusinessLines.push(businessLine)
        }
        return updatedSelectedBusinessLines
      })
    }, [])

    const renderRow = useCallback((row, index) => {
      return (
        <TableRow
          key={index}
          sx={{
            backgroundColor: index % 2 === 0 ? '#00000008' : GLOBAL.WHITE,
            height: GLOBAL.MARGIN_LG,
          }}
        >
          <TableCell>{row.dataDomainName}</TableCell>
          <TableCell>{row.dataEntities.join(', ')}</TableCell>
          <TableCell>{row.primaryAccess}</TableCell>
          <TableCell>{row.secondaryAccess}</TableCell>
        </TableRow>
      )
    }, [])

    const headCells = [
      { id: 'dataDomain', label: 'Data Domain', width: '15%' },
      { id: 'dataSets', label: 'Data Sets', width: '65%' },
      {
        id: 'primaryAccess',
        label: 'Primary Access',
        tooltipText: 'Level of access for programs via the assigned business lines',
        width: '10%',
      },
      {
        id: 'secondaryAccess',
        label: 'Secondary Access',
        tooltipText: 'Level of access to all programs',
        width: '10%',
      },
    ]

    useEffect(() => {
      getAllRoles()
    }, [getAllRoles])

    useEffect(() => {
      if (userId) {
        getPreviewWithUserId(userId)
      }
    }, [getPreviewWithUserId, userId])

    const renderNameTitle = useCallback((user) => {
      return (
        <Typography component="span" sx={{ color: GLOBAL.LINK_PRIMARY_COLOR, fontWeight: 'bold' }}>
          {[user.firstName, user.lastName].filter(Boolean).join(' ') +
            (user.jobTitle ? `, ${user.jobTitle}` : '')}
        </Typography>
      )
    }, [])

    return (
      <>
        {!userId && (
          <Typography sx={{ pl: 3, pr: 3 }} variant="h1">
            {t('user-provisioning.title')}
          </Typography>
        )}
        {((userId && isProviderEditing) || !userId) && (
          <Stepper activeStep={isProviderEditing ? activeStep - 1 : activeStep} sx={{ p: 3 }}>
            {selectedSteps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                  sx={{ '& .MuiStepLabel-label': { fontWeight: 'bold' } }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Box component={Paper} sx={{ mx: 3 }}>
          {activeStep === 0 && (
            <Box>
              <Typography sx={{ ml: 3, pt: 3 }} variant="h4">
                {t('user-provisioning.user-profile.title-text')}
              </Typography>
              <Grid container spacing={3} sx={{ p: 3 }}>
                <Grid item xs={3}>
                  <MISTextField
                    error={isError() && hasError('userDetails.firstName')}
                    helperText={getError('userDetails.firstName')}
                    id="firstName"
                    inputProps={{ maxLength: 50 }}
                    label={t('user-provisioning.userDetails.first-name')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleUserDetailChange(event?.target.value, 'firstName')
                    }
                    required
                    value={userDetails?.firstName || ''}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MISTextField
                    error={isError() && hasError('userDetails.lastName')}
                    helperText={getError('userDetails.lastName')}
                    id="lastName"
                    inputProps={{ maxLength: 50 }}
                    label={t('user-provisioning.userDetails.last-name')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleUserDetailChange(event?.target.value, 'lastName')
                    }
                    required
                    value={userDetails?.lastName || ''}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MISTextField
                    error={isError() && hasError('userDetails.jobTitle')}
                    helperText={getError('userDetails.jobTitle')}
                    id="jobTitle"
                    inputProps={{ maxLength: 50 }}
                    label={t('user-provisioning.userDetails.job-title')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleUserDetailChange(event?.target.value, 'jobTitle')
                    }
                    value={userDetails?.jobTitle || ''}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MISTextField
                    error={isError() && hasError('userDetails.email')}
                    helperText={getError('userDetails.email')}
                    id="email"
                    inputProps={{ maxLength: 50 }}
                    label={t('user-provisioning.userDetails.email')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleUserDetailChange(event?.target.value, 'email')
                    }
                    required
                    value={userDetails?.email || ''}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MISTextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            onMouseUp={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={isError() && hasError('userDetails.password')}
                    helperText={getError('userDetails.password')}
                    id="password"
                    inputProps={{ maxLength: 50 }}
                    label={t('user-provisioning.userDetails.password')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleUserDetailChange(event?.target.value, 'password')
                    }
                    required
                    type={showPassword ? 'text' : 'password'}
                    value={userDetails?.password || ''}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {activeStep === 1 && (
            <>
              <Typography sx={{ ml: 3, pt: 3 }} variant="h4">
                {t('user-provisioning.role-assignment.text1')}
                {renderNameTitle(userDetails)}
                {t('user-provisioning.role-assignment.text1-1')} *
              </Typography>
              <Grid container sx={{ ml: 3, px: 1.5 }}>
                <MISRadioGroup
                  label=""
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const selectedRole = allRoles.find((role) => role.name === event.target.value)
                    setSelectedRole(selectedRole || undefined)
                  }}
                  options={[]}
                  value={selectedRole?.name}
                >
                  {allRoles.map((role) => (
                    <Grid container key={role.id} sx={{ my: 1.5 }}>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={<MISRadio />}
                          label={<Typography sx={{ fontWeight: 'bold' }}>{role.name}</Typography>}
                          value={role.name}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{role.description}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </MISRadioGroup>
              </Grid>

              <Typography sx={{ pl: 3, pt: 2 }} variant="h4">
                {t('user-provisioning.role-assignment.text2')}
                {renderNameTitle(userDetails)}
                {t('user-provisioning.role-assignment.text2-1')} *
              </Typography>
              <MISRadioGroup
                label=""
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setIsManager(event.target.value === 'true' ? true : false)
                }
                options={YesNoOptions}
                sx={{ pl: 3 }}
                value={isManager}
              />
            </>
          )}
          {activeStep === 2 && (
            <>
              <Typography sx={{ ml: 3, pt: 3 }} variant="h4">
                {t('user-provisioning.business-line-assignment.text1')}
                <Typography
                  component="span"
                  sx={{ color: GLOBAL.LINK_PRIMARY_COLOR, fontWeight: 'bold' }}
                >
                  {t('user-provisioning.business-line-assignment.text1-1')}
                </Typography>
                {t('user-provisioning.business-line-assignment.text1-2')}
                {renderNameTitle(userDetails)}
                {t('user-provisioning.business-line-assignment.text1-3')}
                <Typography
                  component="span"
                  sx={{ color: GLOBAL.LINK_PRIMARY_COLOR, fontWeight: 'bold' }}
                >
                  {t('user-provisioning.business-line-assignment.text1-4')}
                </Typography>
                {t('user-provisioning.business-line-assignment.text1-5')}
              </Typography>

              <Grid container sx={{ ml: 3, px: 1.5 }}>
                {allBusinessLines?.map((businessLine) => (
                  <Grid container key={businessLine.id} sx={{ my: 1.5 }}>
                    <Grid item key={'form-control-label-' + businessLine.id} xs={2}>
                      <FormControlLabel
                        control={
                          <MISCheckbox
                            checked={selectedBusinessLines.some(
                              (selectedBusinessLine) => selectedBusinessLine.id === businessLine.id
                            )}
                            key={'checkbox-' + businessLine.id}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              handleSelectBusinessLine(businessLine)
                            }}
                          />
                        }
                        label={
                          <Typography sx={{ fontWeight: 'bold' }}>{businessLine.name}</Typography>
                        }
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>{businessLine.description}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {activeStep === 3 && (
            <Box sx={{ pt: 1 }}>
              {((userId && isProviderEditing) || !userId) && (
                <Typography gutterBottom sx={{ m: 3 }} variant="h4">
                  {t('user-provisioning.summary.text1')}
                  {renderNameTitle(userDetails)}
                </Typography>
              )}
              {permissionModel && (
                <>
                  <Box sx={{ m: 3 }}>
                    <Typography variant="caption">
                      {[
                        [userDetails.firstName, userDetails.lastName].filter(Boolean).join(' ') +
                          (userDetails.jobTitle ? `, ${userDetails.jobTitle}` : ''),
                        userDetails.email &&
                          `${t('user-provisioning.userDetails.email')}: ${userDetails.email}`,
                        selectedRole?.name &&
                          `${t('user-provisioning.summary.role')}: ${selectedRole.name}`,
                        `${t('user-provisioning.summary.is-manager')}: ${isManager ? 'Yes' : 'No'}`,
                      ]
                        .filter(Boolean)
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </Typography>
                  </Box>
                  <Box sx={{ m: 3 }}>
                    {selectedBusinessLines.map((line, index) => (
                      <Box key={index} sx={{ marginBottom: 2, marginTop: 1 }}>
                        <Typography>
                          <strong>{line.name}:</strong> {line.description}
                        </Typography>
                        <Typography variant="body1">
                          <strong>{t('user-provisioning.summary.programs-associated')}</strong>{' '}
                          {transformAssociatedBusinessLines(line)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Typography gutterBottom sx={{ mx: 3 }} variant="h4">
                    {t('user-provisioning.summary.text2')}
                    {renderNameTitle(userDetails)}
                    {t('user-provisioning.summary.text2-1')}
                  </Typography>
                  <Box sx={{ m: 1 }}>
                    <MISTable data={permissionModel} headers={headCells} renderRow={renderRow} />
                  </Box>
                </>
              )}
            </Box>
          )}
          {activeStep === 4 && (
            <>
              <Box sx={{ padding: 3 }}>
                <Typography gutterBottom variant="h5">
                  {t('user-provisioning.success.text1')}
                  {userDetails.firstName} {userDetails.lastName}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', p: 3 }}>
                <MISButton onClick={handleNewUserButtonClick}>
                  {t('user-provisioning.success.button1')}
                </MISButton>
              </Box>
            </>
          )}

          {activeStep !== 0 && activeStep !== 4 && ((userId && isProviderEditing) || !userId) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
              {!(activeStep === 1 && isProviderEditing) && (
                <MISButton color="secondary" onClick={handleBackButtonClick}>
                  Back
                </MISButton>
              )}
              <MISButton
                disabled={
                  (activeStep === 1 && (!selectedRole || isManager === undefined)) ||
                  (activeStep === 2 && selectedBusinessLines.length === 0)
                }
                onClick={handleNextButtonClick}
              >
                {activeStep !== 3 ? 'Next' : 'Confirm'}
              </MISButton>
            </Box>
          )}
          {activeStep === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
              <MISButton onClick={handleNextButtonClick}>Next</MISButton>
            </Box>
          )}
        </Box>
      </>
    )
  }
)

UserProvisioning.displayName = 'UserProvisioning'
export default UserProvisioning
