import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISTextField from 'common/components/form/MISTextField'
import MISLink from 'common/components/MISLink'
import { useSnack } from 'common/components/snackbar/useSnack'
import MISTable, { PaginationProps } from 'common/components/table/MISTable'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { tagsAtom } from 'recoil/atoms'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  DocumentControllerService,
  DocumentDTO,
  PageDocumentDTO,
} from 'services/openapi'
import { MIS_DOCUMENT_TITLE } from 'services/terminologyConstants'
import { MODALS } from './constants'
import AddEditDocumentDialog from './Dialogs/AddEditDocumentDialog'
import WarningDialog from './Dialogs/WarningDialog'
import DocumentSectionHeader from './DocumentSectionHeader/DocumentSectionHeader'
import { downloadFile } from './utils'

export type DocumentProps = {
  entityId: string
  entityType: string
  breadcrumb: string
}

const ENTITY = 'Documents'

const headCells = [
  {
    id: 'description',
    label: 'Document Description',
    width: '25%',
  },
  {
    id: 'fileName',
    label: 'File Name',
    width: '10%',
  },
  {
    id: 'ontologyName',
    label: 'Ontology Name',
    width: '35%',
  },
  {
    id: 'addedOn',
    label: 'Added On',
    width: '10%',
  },
  {
    id: 'addedBy',
    label: 'Added By',
    width: '10%',
  },
  {
    id: 'source',
    label: 'Source',
    width: '10%',
  },
]

const moreCells = [
  {
    id: 'download',
    label: 'Download',
  },
  {
    id: 'edit',
    label: 'Edit Properties',
  },
  {
    id: 'delete',
    label: 'Delete',
  },
]

const Documents = ({ breadcrumb, entityId, entityType }: DocumentProps) => {
  const { t } = useTranslation('common')
  const auth = useAuth()
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    id: '',
    open: false,
  })
  const navigate = useNavigate()
  const { handleApiError } = useErrorHandler()

  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState<PaginationProps>({
    order: 'asc',
    orderBy: '',
    page: 0,
    rowsPerPage: 10,
  })
  const [rowData, setRowData] = useState<DocumentDTO[]>([])
  const [documentListResponse, setDocumentListResponse] = useState<PageDocumentDTO>({})
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [addEditDocumentDialog, setAddEditDocumentDialog] = useState({
    data: {},
    edit: false,
    open: false,
  })
  const { showSnackSuccess } = useSnack()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const titleOptions: CodedConceptDto[] = useRecoilValue(terminologySelector(MIS_DOCUMENT_TITLE))
  const [tags, setTagsValues] = useRecoilState(tagsAtom)

  useEffect(() => {
    const getTagsValues = async () => {
      try {
        const response = await DocumentControllerService.getAllTags()
        if (response) {
          setTagsValues(response.content)
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    getTagsValues()
  }, [handleApiError, setTagsValues])

  const getDocAssociationLabel = useCallback((entity?: string) => {
    switch (entity) {
      case 'CLIENT_FILE':
        return 'Client'
    }
    return 'First Source'
  }, [])

  const getDocAssociationNav = useCallback((entity?: string, entityId?: string) => {
    switch (entity) {
      case 'CLIENT_FILE':
        return '/clients/client-record/' + entityId
    }
    return ''
  }, [])

  const handleSearchDocuments = useCallback(() => {
    DocumentControllerService.getDocumentList(
      entityId,
      searchText,
      [],
      pagination.page,
      pagination.rowsPerPage,
      [`${String(pagination.orderBy)},${pagination.order}`]
    )
      .then((response) => {
        setDocumentListResponse(response)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [
    entityId,
    searchText,
    pagination.page,
    pagination.rowsPerPage,
    pagination.orderBy,
    pagination.order,
    handleApiError,
  ])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    handleSearchDocuments()
  }, [handleSearchDocuments])

  useEffect(() => {
    if (searchText.length >= 3) {
      handleSearchDocuments()
    }
  }, [handleSearchDocuments, searchText.length])

  const onDeleteWarningClose = useCallback(() => {
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog])

  const onDeleteWarningSave = useCallback(() => {
    const onRemoveDoc = (id: string) => {
      DocumentControllerService.deleteDocument(id)
        .then((response) => {
          showSnackSuccess(t('documents.successful-delete-message'))
          handleSearchDocuments()
        })
        .catch((error) => {
          handleApiError(error)
        })
    }

    onRemoveDoc(deleteConfirmationDialog.id)
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog, showSnackSuccess, t, handleSearchDocuments, handleApiError])

  const navigateToEntity = useCallback(
    (nav: string) => {
      navigate(nav)
    },
    [navigate]
  )

  const getSourceList = useCallback(
    (document: DocumentDTO) => {
      return document.associatedEntities
        ?.filter((x) => x.entityType !== entityType)
        .map((entity) => {
          return {
            key: entity.entityId,
            label: getDocAssociationLabel(entity?.entityType),
            nav: getDocAssociationNav(entity.entityType, entity.entityId),
          }
        })
    },
    [entityType, getDocAssociationLabel, getDocAssociationNav]
  )

  const handleOpenDocumentUpload = useCallback(() => {
    setAddEditDocumentDialog({ data: {}, edit: false, open: true })
  }, [])

  const handleSelect = useCallback(
    (option: string, row: DocumentDTO) => {
      if (row && row.id) {
        switch (option) {
          case 'download': {
            try {
              const token = auth.user?.access_token || ''
              downloadFile(row.id, token, row.fileName)
            } catch (error) {
              handleApiError(error)
            }
            break
          }
          case 'edit': {
            setAddEditDocumentDialog({ data: { document: row }, edit: true, open: true })
            break
          }
          case 'delete': {
            setDeleteConfirmationDialog({ id: row.id, open: true })
            break
          }
        }
      }
      setAnchorEl(null)
    },
    [auth.user?.access_token, handleApiError]
  )

  const handleMoreClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>, row: DocumentDTO) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleClose = useCallback(
    (update: boolean) => {
      setAddEditDocumentDialog({ data: {}, edit: false, open: false })
      if (update) {
        handleSearchDocuments()
      }
    },
    [handleSearchDocuments]
  )

  const handlePopoverClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setSelectedIndex(-1)
    setAnchorEl(null)
  }, [])

  const renderRow = useCallback(
    (row, index) => {
      return (
        <TableRow className="row" hover key={row.id}>
          <TableCell className="cell special" scope="row">
            {row.description}
          </TableCell>
          <TableCell className="cell">{row.fileName}</TableCell>
          <TableCell className="cell">
            {row.ontologyTitle
              ? titleOptions.find((x) => x.code === row?.ontologyTitle?.code)?.name
              : ''}
          </TableCell>
          <TableCell className="cell">{row.authoredDatetime}</TableCell>
          <TableCell className="cell">{row.author}</TableCell>
          <TableCell className="cell">
            {getSourceList(row)?.map((source) => {
              return (
                <div key={source.key}>
                  <MISLink
                    aria-current="page"
                    color="primary"
                    key="link-source"
                    onClick={(event) => {
                      event.preventDefault()
                      navigateToEntity(source.nav)
                    }}
                    underline="hover"
                    variant="inherit"
                  >
                    {source.label}
                  </MISLink>
                </div>
              )
            })}
          </TableCell>
          <TableCell className="cell">
            <IconButton
              aria-controls={anchorEl ? 'document-more' + entityId : undefined}
              aria-expanded={anchorEl ? 'true' : undefined}
              aria-haspopup="true"
              aria-label="more"
              id={'document-more' + row.id}
              key={'document-more' + row.id}
              onClick={(event) => {
                setSelectedIndex(index)
                handleMoreClick(event, row)
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              id={'document-more-' + row.id}
              key={'document-more-' + row.id}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onClose={handlePopoverClose}
              open={Boolean(anchorEl) && selectedIndex === index}
            >
              <Box>
                {moreCells.map((option) => (
                  <MenuItem
                    divider
                    key={option.id + row.id}
                    onClick={() => {
                      handleSelect(option.id, row)
                    }}
                    sx={{ height: 54, width: 300 }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Box>
            </Popover>
          </TableCell>
        </TableRow>
      )
    },
    [
      anchorEl,
      entityId,
      getSourceList,
      handleMoreClick,
      handlePopoverClose,
      handleSelect,
      navigateToEntity,
      selectedIndex,
      titleOptions,
    ]
  )

  useEffect(() => {
    if (documentListResponse) {
      setRowData(documentListResponse?.content || [])
    }
  }, [documentListResponse])

  return (
    <>
      <DocumentSectionHeader onAdd={handleOpenDocumentUpload} />
      <div className="governance-agency-list">
        <Box className="container special">
          <Box className="field">
            <Typography component="div" gutterBottom variant="h6">
              {t('documents.search-text-field')}
            </Typography>
            <Divider />
            <Tooltip arrow placement="top" title={t('documents.search-text-tooltip')}>
              <MISTextField
                id="search-text-tooltip"
                label={t('documents.search-text-tooltip')}
                onChange={handleSearchTextChange}
                placeholder={t('documents.search-text-placeholder')}
                value={searchText}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box className="container">
          <Box className="field" />
          <MISTable
            data={rowData}
            headers={headCells}
            renderRow={renderRow}
            totalElements={documentListResponse.totalElements}
            updatePagination={setPagination}
          />
        </Box>
        <AddEditDocumentDialog
          editData={addEditDocumentDialog.edit ? addEditDocumentDialog.data : undefined}
          entityId={entityId}
          entityType={entityType}
          onCloseCallback={handleClose}
          openDialog={addEditDocumentDialog.open}
          tags={tags}
        />
        <WarningDialog
          entity={ENTITY}
          onCancel={onDeleteWarningClose}
          onSave={onDeleteWarningSave}
          open={deleteConfirmationDialog.open}
          type={MODALS.DELETE_WARNING}
        />
      </div>
    </>
  )
}

export default Documents
