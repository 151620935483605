import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@mui/material'
import TEMPLATES from '../../modules/TemplatesToolbar/Templates'

type NoAccessBannerProps = {
  name?: string
  added?: boolean
}

const NoAccessBanner = ({ added, name }: NoAccessBannerProps) => {
  const { t } = useTranslation('common')

  const [alertTitle, setAlertTitle] = useState<string>()

  useEffect(() => {
    if (name) {
      const template = Object.values(TEMPLATES).find((each) => each.name === name)
      if (template) setAlertTitle(t(template.key))
    }
  }, [name, t])

  return (
    <>
      {added && (
        <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          {t('charting.templates.no-access.body')}
        </Alert>
      )}
    </>
  )
}

export default NoAccessBanner
