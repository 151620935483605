import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Divider, Grid, TableCell, TableRow } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISChip from 'common/components/form/MISChip'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import MISTable, { PaginationProps } from 'common/components/table/MISTable'
import { getNameFromCodedConcept } from 'common/utils/CodedConceptUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getFormattedOptions } from 'modules/shared/utils'
import { expertiseAtom } from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  PagePersonnelDTO,
  PersonnelControllerService,
  PersonnelDTO,
  PersonnelJobFunctionDTO,
  PersonnelNameDTO,
} from 'services/openapi'
import { MIS_GENDER_VOCAB_NAME, MIS_PROVIDER_ROLE_TYPES } from 'services/terminologyConstants'
import './SearchProviderStaff.scss'

const headCells = [
  {
    id: 'name',
    key: 'name',
    label: 'provider-staff.search.name',
    sortable: true,
    translated: true,
    width: '25%',
  },
  {
    id: 'gender',
    key: 'gender',
    label: 'provider-staff.search.gender',
    sortable: true,
    translated: true,
    width: '25%',
  },
  {
    id: 'expertise',
    key: 'expertise',
    label: 'provider-staff.search.expertise',
    translated: true,
    width: '25%',
  },
  {
    id: 'jobFunctions',
    key: 'jobFunctions',
    label: 'provider-staff.add-edit.job-functions',
    translated: true,
    width: '25%',
  },
]

const DEFAULT_PAGE_SIZE = 5

const defaultFilters: any = {
  expertise: '',
  gender: '',
  isStaff: '',
  jobFunctions: [],
  jobInternalExternal: '',
  jobStatus: '',
  jobTitle: '',
  name: '',
  user: '',
}

const SearchProviderStaff = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const genderOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_GENDER_VOCAB_NAME)
  )
  const jobFunctionOptions = useRecoilValue(terminologySelector(MIS_PROVIDER_ROLE_TYPES))
  const { handleApiError } = useErrorHandler()
  const [searchFilters, setSearchFilters] = useState(defaultFilters)
  const [searchResults, setSearchResults] = useState<PersonnelDTO[]>([])
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [expertiseValues, setExpertiseValues] = useRecoilState(expertiseAtom)
  const [totalElements, setTotalElements] = useState(0)
  const [pagination, setPagination] = useState<PaginationProps>({
    order: 'asc',
    orderBy: '',
    page: 0,
    rowsPerPage: 10,
  })

  useEffect(() => {
    const getExpertiseValues = async () => {
      try {
        const response = await PersonnelControllerService.getAllExpertises()
        if (response) {
          setExpertiseValues(response.content)
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    getExpertiseValues()
  }, [setExpertiseValues, handleApiError])

  const handleChange = useCallback(
    (value: any, field: string) => {
      const updatedSearchFilters = {
        ...searchFilters,
        [field]: value,
      }
      setSearchFilters(updatedSearchFilters)
    },
    [searchFilters, setSearchFilters]
  )

  const validate = useCallback(() => {
    const filterKeys = Object.keys(searchFilters)
    let filterPresent = false
    if (searchFilters.jobFunctions.length > 0) {
      filterPresent = true
    }
    if (searchFilters.gender) {
      filterPresent = true
    }
    if (searchFilters.expertise.length > 0) {
      filterPresent = true
    }
    filterKeys.forEach((key) => {
      if (filterPresent) return
      if (typeof searchFilters[key] === 'string' && searchFilters[key] !== '') {
        filterPresent = true
      } else {
        filterPresent = false
      }
      if (typeof searchFilters[key] === 'boolean') {
        filterPresent = true
      }
    })
    setHasErrors(!filterPresent)

    return !!filterPresent
  }, [searchFilters])

  const handleSearch = useCallback(() => {
    if (validate()) {
      const {
        expertise,
        gender,
        isStaff,
        jobFunctions,
        jobInternalExternal,
        jobStatus,
        jobTitle,
        name,
        user,
      } = searchFilters

      let genderCode: string | undefined = ''
      let genderCodeSystemOid: string | undefined = ''
      if (gender) {
        genderCode = genderOptions?.find((x) => x.id === gender.id)?.code
        genderCodeSystemOid = genderOptions?.find((x) => x.id === gender.id)?.codeSystemOid
      }

      PersonnelControllerService.searchPersonnel(
        name,
        expertise,
        genderCodeSystemOid,
        genderCode,
        user === '' ? undefined : user,
        '',
        '',
        '',
        jobStatus === '' ? undefined : jobStatus,
        isStaff === '' ? undefined : isStaff,
        jobTitle,
        jobInternalExternal === '' ? undefined : jobInternalExternal,
        jobFunctions.length > 0
          ? jobFunctions.map((id: string) => {
              const jobFunction = jobFunctionOptions.find((x) => x.id === id)
              return `${jobFunction?.code}#${jobFunction?.codeSystemOid}`
            })
          : [],
        pagination.page,
        DEFAULT_PAGE_SIZE,
        [`${String(pagination.orderBy)},${pagination.order}`]
      )
        .then((response: PagePersonnelDTO) => {
          setSearchResults(response?.content || [])
          setTotalElements(response?.totalElements || 0)
          setDisplaySearchResults(true)
        })
        .catch((error) => {
          handleApiError(error)
        })
    }
  }, [
    validate,
    searchFilters,
    pagination.page,
    pagination.orderBy,
    pagination.order,
    genderOptions,
    jobFunctionOptions,
    handleApiError,
  ])

  const renderRow = useCallback(
    (row, index) => {
      const preferredNameIndex =
        (searchResults &&
          searchResults[index].names?.findIndex((name: PersonnelNameDTO) => name.preferredName)) ||
        0
      const preferredName = row?.names?.[preferredNameIndex]
      return (
        <TableRow className="special-row" hover key={row.id}>
          <TableCell component="th" scope="row">
            <MISButton
              onClick={() => navigate(`/provider-staff-record/${row.id}`)}
              sx={{ p: 0, textAlign: 'left' }}
              variant="text"
            >
              {[preferredName?.firstName, preferredName?.middleName, preferredName?.lastName]
                .filter(Boolean)
                .join(' ')
                .trim()}
            </MISButton>
          </TableCell>
          <TableCell>{getNameFromCodedConcept(genderOptions, row.gender)}</TableCell>
          <TableCell>{row.expertise}</TableCell>
          <TableCell>
            {row?.jobFunctions?.map((jf: PersonnelJobFunctionDTO) => {
              return (
                <div className="chip-container" key={jf.id}>
                  {[getNameFromCodedConcept(jobFunctionOptions, jf.jobFunction), jf.jobTitle]
                    .filter(Boolean)
                    .join(' - ')
                    .trim()}
                  <span style={{ float: 'right' }}>
                    {jf.isStaff && <MISChip className="chip" label="Staff" variant="outlined" />}
                    <MISChip
                      className="chip"
                      label={jf.isInternal ? 'Internal' : 'External'}
                      variant="outlined"
                    />
                    <MISChip
                      className="chip"
                      color={jf.isActive ? 'primary' : 'default'}
                      label={jf.isActive ? 'Active' : 'Inactive'}
                    />
                  </span>
                </div>
              )
            })}
          </TableCell>
        </TableRow>
      )
    },
    [genderOptions, jobFunctionOptions, navigate, searchResults]
  )

  const emptyTable = useCallback(() => {
    return (
      <TableRow className="row">
        <TableCell className="cell" colSpan={3}>
          <Alert severity="info">{t('provider-staff.search.search-no-results-title')}</Alert>
        </TableCell>
      </TableRow>
    )
  }, [t])

  const tableFooter = useCallback(() => {
    return (
      <TableRow className="row">
        <TableCell className="cell" colSpan={4}>
          <Box className="add-provider-staff">
            <Box className="container">
              <MISButton
                className={!displaySearchResults ? 'button primary disabled' : 'button primary'}
                disabled={!displaySearchResults}
                onClick={() => navigate('/provider-staff/create')}
              >
                {t('provider-staff.add-edit.add-provider-staff-text')}
              </MISButton>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    )
  }, [displaySearchResults, navigate, t])

  const getSearchResults = useCallback(() => {
    return (
      <div className="search-results">
        <MISTable
          data={searchResults ?? []}
          emptySituation={{
            emptyNode: emptyTable(),
            isEmpty: !!searchResults && searchResults.length <= 0,
          }}
          footer={tableFooter()}
          headers={headCells}
          renderRow={renderRow}
          totalElements={totalElements}
          updatePagination={setPagination}
        />
      </div>
    )
  }, [searchResults, emptyTable, tableFooter, renderRow, totalElements])

  const getSearchFilters = useCallback(() => {
    const {
      expertise,
      gender,
      isStaff,
      jobFunctions,
      jobInternalExternal,
      jobStatus,
      jobTitle,
      name,
      user,
    } = searchFilters

    return (
      <form className="search-content" onSubmit={(event) => event.preventDefault()}>
        {hasErrors && (
          <Alert className="error" severity="error">
            {t('provider-staff.search.search-no-filters-applied')}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MISTextField
              id="name"
              label={t('provider-staff.search.name')}
              onChange={(event) => handleChange(event.target.value, 'name')}
              value={name}
            />
          </Grid>
          {genderOptions && (
            <Grid item xs={3}>
              <MISSelectDropdown
                id="gender"
                label={t('provider-staff.search.gender')}
                onChange={(event) => handleChange(event.target.value, 'gender')}
                options={getFormattedOptions(genderOptions)}
                value={genderOptions.find((o: CodedConceptDto) => o.code === gender?.code)}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <MISMultiValueAutocomplete
              allowFreeText={false}
              label={t('provider-staff.search.expertise')}
              onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                handleChange(
                  newValue.map((value) => value.value),
                  'expertise'
                )
              }}
              options={expertiseValues?.map((v) => ({ label: v || '', value: v || '' })) || []}
              value={
                (expertise && expertise.map((e) => ({ label: e || '', value: e || '' }))) || []
              }
            />
          </Grid>
          <Grid item xs={2}>
            <MISRadioGroup
              id="users"
              label={t('provider-staff.search.users')}
              onChange={(event) => handleChange(event.target.value === 'Yes', 'user')}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={user ? 'Yes' : user === false ? 'No' : ''}
            />
          </Grid>
          {jobFunctionOptions && (
            <Grid item xs={3}>
              <MISSelectMultiDropdown
                label={t('provider-staff.add-edit.job-functions')}
                onChange={(event) => handleChange(event.target.value, 'jobFunctions')}
                options={jobFunctionOptions.map((option) => {
                  return {
                    label: option.name,
                    value: option.id,
                  }
                })}
                value={jobFunctions}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <MISTextField
              id="job-title"
              label={t('provider-staff.add-edit.job-title')}
              onChange={(event) => handleChange(event.target.value, 'jobTitle')}
              value={jobTitle}
            />
          </Grid>
          <Grid item xs={2}>
            <MISRadioGroup
              id="staff"
              label={t('provider-staff.add-edit.staff')}
              onChange={(event) => handleChange(event.target.value === 'Yes', 'isStaff')}
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={isStaff ? 'Yes' : isStaff === false ? 'No' : ''}
            />
          </Grid>
          <Grid item xs={2}>
            <MISRadioGroup
              id="status"
              label={t('provider-staff.add-edit.status')}
              onChange={(event) => handleChange(event.target.value === 'ACTIVE', 'jobStatus')}
              options={[
                { label: t('provider-staff.add-edit.status-active'), value: 'ACTIVE' },
                { label: t('provider-staff.add-edit.status-inactive'), value: 'INACTIVE' },
              ]}
              value={jobStatus ? 'ACTIVE' : jobStatus === false ? 'INACTIVE' : ''}
            />
          </Grid>
          <Grid item xs={2}>
            <MISRadioGroup
              id="internal-external"
              label={t('provider-staff.add-edit.internal-external')}
              onChange={(event) =>
                handleChange(event.target.value === 'INTERNAL', 'jobInternalExternal')
              }
              options={[
                { label: t('provider-staff.add-edit.internal'), value: 'INTERNAL' },
                { label: t('provider-staff.add-edit.external'), value: 'EXTERNAL' },
              ]}
              value={
                jobInternalExternal ? 'INTERNAL' : jobInternalExternal === false ? 'EXTERNAL' : ''
              }
            />
          </Grid>
        </Grid>
        <Divider />
        <Box className="actions">
          <MISButton onClick={() => handleSearch()} sx={{ marginLeft: '1rem' }} type="submit">
            {t('provider-staff.search.search-button')}
          </MISButton>
        </Box>
      </form>
    )
  }, [
    genderOptions,
    expertiseValues,
    handleChange,
    handleSearch,
    hasErrors,
    jobFunctionOptions,
    searchFilters,
    t,
  ])

  return (
    <MISBaseContainer>
      <div className="search-provider-staff">
        <MISImageContentContainer>
          <Content
            content={getSearchFilters()}
            heading={t('provider-staff.search.search-title')}
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
        {displaySearchResults && (
          <MISImageContentContainer hideImage>
            <Content
              content={getSearchResults()}
              heading={t('provider-staff.search.search-results-title')}
              isCollapsible={false}
              isDivider
            />
          </MISImageContentContainer>
        )}
      </div>
    </MISBaseContainer>
  )
}

export default SearchProviderStaff
