import { ChangeEvent, Component } from 'react'
import NoAccessBanner from './NoAccessBanner'
import { ITemplate } from '../../blots/TemplateBlot'

export type NoAccessTemplateState = { name?: string; added?: boolean }

type NoAccessTemplateProps = { data?: NoAccessTemplateState }

class NoAccessTemplate
  extends Component<NoAccessTemplateProps, NoAccessTemplateState>
  implements ITemplate
{
  type = 'NoAccessTemplate'

  constructor(props: NoAccessTemplateProps) {
    super(props)
    this.state = { ...this.state, ...props.data }
  }

  getData = () => {
    return { ...this.state }
  }

  getEmptyData = () => {
    return { name: undefined }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [event.target.id]: Number.parseInt(event.target.value),
    })
  }

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return <NoAccessBanner added={this.state.added} name={this.state.name} />
  }
}

export default NoAccessTemplate
