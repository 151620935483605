import { useParams } from 'react-router-dom'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import useGroups from 'modules/shared/hooks/useGroups'
import ClientRecordHeaderDetails from './ClientRecordHeaderDetails'

type ClientRecordHeaderProps = {
  allowChangeContext?: boolean
}
const ClientRecordHeader = ({ allowChangeContext = false }: ClientRecordHeaderProps) => {
  const { clientId } = useParams()
  const { groupId } = useParams()
  const { clientDetails } = useClientDetails(clientId)
  const { chartingGroupInContext } = useGroups(groupId)
  return (
    <div
      style={{
        backgroundColor: 'white',
        marginBottom: '24px',
        marginRight: '240px',
        marginTop: '-48px',
        position: 'sticky',
        top: 0,
        zIndex: 11,
      }}
    >
      <ClientRecordHeaderDetails
        allowChangeContext={allowChangeContext}
        client={clientDetails}
        group={chartingGroupInContext}
      />
    </div>
  )
}

export default ClientRecordHeader
