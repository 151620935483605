import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { Breadcrumb } from 'common/components/breadcrumb/Breadcrumb'
import MISChip from 'common/components/form/MISChip'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import MISTable from 'common/components/table/MISTable'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getDomainNameById } from 'modules/shared/utils'
import { domainsAtom } from 'recoil/atoms'
import { govAgencyState } from 'recoil/govAgency'
import { terminologySelector } from 'recoil/terminology'
import { GovernanceAgencyControllerService } from 'services/openapi'
import { GOVERNANCE_AGENCY_TYPES } from 'services/terminologyConstants'
import './GovernanceAgencyList.scss'

const headCells = [
  {
    id: 'name',
    label: 'Agency Name',
    translated: true,
    width: '15%',
  },
  {
    id: 'agencyType',
    label: 'Governance Agency Type',
    translated: true,
    width: '40%',
  },
  {
    id: 'domains',
    label: 'Domains',
    translated: true,
    width: '25%',
  },
  {
    id: 'startDate',
    label: 'Start Date',
    translated: true,
    width: '10%',
  },
  {
    id: 'endDate',
    label: 'End Date',
    translated: true,
    width: '10%',
  },
]

function createRow(
  id,
  agencyName,
  governanceAgencyType,
  agencyType,
  startDate,
  endDate,
  domainAssociations
) {
  return {
    agencyName,
    agencyType,
    domainAssociations,
    endDate,
    governanceAgencyType,
    id,
    startDate,
  }
}

function GovernanceAgencyList({ breadcrumb }) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { handleApiError } = useErrorHandler()
  const agencyTypeOptions = useRecoilValue(terminologySelector(GOVERNANCE_AGENCY_TYPES))
  const [agencyName, setAgencyName] = useState('')
  const [pagination, setPagination] = useState({
    order: 'asc',
    orderBy: '',
    page: 0,
    rowsPerPage: 10,
  })
  const [rowData, setRowData] = useState([])
  const [governanceAgencyListResponse, setGovernanceAgencyListResponse] = useState(null)
  const resetAgencyDetails = useResetRecoilState(govAgencyState)
  const domainsSet = useRecoilValue(domainsAtom)

  const handleAgencyNameChange = (event) => {
    setAgencyName(event.target.value)
  }

  const handleClearSearch = () => {
    setAgencyName('')
  }

  const handleSearchGovernanceAgencies = useCallback(() => {
    GovernanceAgencyControllerService.searchAgencyListForSpecifiedType(
      undefined,
      undefined,
      agencyName,
      true,
      pagination.page,
      pagination.rowsPerPage,
      `${pagination.orderBy},${pagination.order}`
    )
      .then((response) => {
        setGovernanceAgencyListResponse(response)
      })
      .catch((error) => {
        handleApiError(error)
      })
  }, [
    agencyName,
    pagination.page,
    pagination.rowsPerPage,
    pagination.orderBy,
    pagination.order,
    handleApiError,
  ])

  const navigateCreateGovernanceAgency = useCallback(() => {
    resetAgencyDetails()
    navigate('/admin/governance-agencies/create')
  }, [navigate, resetAgencyDetails])

  const handleClick = useCallback(
    async (event, governanceAgency) => {
      resetAgencyDetails()
      if (governanceAgency?.governanceAgencyType.some((o) => o.code === 'Department')) {
        try {
          const associations = await GovernanceAgencyControllerService.getAgencyRelationships(
            governanceAgency.id
          )
          const parent = associations.content.find(
            (x) => x.relationshipType.code.toLowerCase() === 'parent'
          )
          if (parent)
            navigate(
              '/admin/governance-agencies/' +
                parent.relatedGovernanceAgencyId +
                '/department/' +
                governanceAgency.id
            )
        } catch (error) {
          handleApiError(error)
        }
      } else {
        navigate('/admin/governance-agencies/edit/' + governanceAgency.id)
      }
    },
    [handleApiError, navigate, resetAgencyDetails]
  )

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        // Call your function here
        handleSearchGovernanceAgencies()
      }
    },
    [handleSearchGovernanceAgencies]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderRow = useCallback(
    (row, _) => {
      return (
        <TableRow className="row" hover key={row.id} onClick={(event) => handleClick(event, row)}>
          <TableCell className="cell special" scope="row">
            {row.agencyName}
          </TableCell>
          <TableCell className="cell">{row.agencyType}</TableCell>
          <TableCell className="cell">
            {row &&
              row.domainAssociations &&
              row.domainAssociations.map((domain) => (
                <Stack direction="row" key={domain.id}>
                  {domain.governanceAgencyDefault && (
                    <>
                      <Typography
                        className="typography"
                        sx={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}
                      >
                        Default for
                      </Typography>
                      <Typography>&nbsp;</Typography>
                    </>
                  )}
                  <Typography className="typography" fontWeight="600">
                    {getDomainNameById(domainsSet, domain.domainId)}
                  </Typography>
                </Stack>
              ))}
          </TableCell>
          <TableCell className="cell">{row.startDate}</TableCell>
          <TableCell className="cell">{row.endDate}</TableCell>
        </TableRow>
      )
    },
    [domainsSet, handleClick]
  )

  const tableFooter = useCallback(() => {
    return (
      <TableRow className="row">
        <TableCell className="cell" colSpan={5}>
          <Box className="cell-container">
            <MISButton
              className="button"
              onClick={navigateCreateGovernanceAgency}
              variant="contained"
            >
              {t('governance-agency.button.add-governance-agency')}
            </MISButton>
          </Box>
        </TableCell>
      </TableRow>
    )
  }, [navigateCreateGovernanceAgency, t])

  useEffect(() => {
    handleSearchGovernanceAgencies()
  }, [handleSearchGovernanceAgencies])

  useEffect(() => {
    if (governanceAgencyListResponse) {
      const rows = governanceAgencyListResponse.content.map((item) => {
        const agencyType = item.governanceAgencyType?.map((govAgencyType, index) => {
          const agencyTypeLabel = agencyTypeOptions.find((a) => a.code === govAgencyType.code)?.name
          return <MISChip key={index} label={agencyTypeLabel} sx={{ m: 0.5 }} />
        })
        return createRow(
          item.id,
          item.name,
          item.governanceAgencyType,
          agencyType,
          item.effective.startDate ? isoDateToDisplayFormat(item.effective.startDate) : '',
          item.effective.endDate ? isoDateToDisplayFormat(item.effective.endDate) : '',
          item.domainAssociations
        )
      })
      setRowData(rows)
    }
  }, [agencyTypeOptions, governanceAgencyListResponse])

  return (
    <div className="governance-agency-list">
      <Breadcrumb breadcrumb={breadcrumb} />
      <Box className="container special">
        <Box className="field">
          <Typography component="div" gutterBottom variant="h6">
            {t('governance-agency.title.governance-agency-search')}
          </Typography>
          <Divider />
          <Tooltip arrow placement="top" title={t('governance-agency.tooltip.agency-name')}>
            <MISTextField
              id="agencyName"
              label={t('governance-agency.tooltip.agency-name')}
              onChange={handleAgencyNameChange}
              onKeyDown={handleEnterPress}
              value={agencyName}
            />
          </Tooltip>
        </Box>
        <Divider className="divider" />
        <Box className="field2">
          <MISButton className="button" onClick={handleClearSearch} variant="outlined">
            {t('common.button.clear')}
          </MISButton>
          <MISButton
            className="button special"
            onClick={handleSearchGovernanceAgencies}
            variant="contained"
          >
            {t('common.button.search')}
          </MISButton>
        </Box>
      </Box>

      <Box className="container">
        <Box className="field" />
        <MISTable
          data={rowData ?? []}
          emptySituation={{ isEmpty: !!rowData && rowData.length <= 0 }}
          footer={tableFooter()}
          headers={headCells}
          renderRow={renderRow}
          totalElements={governanceAgencyListResponse?.totalElements}
          updatePagination={setPagination}
        />
      </Box>
    </div>
  )
}

export default GovernanceAgencyList
