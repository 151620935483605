import AccessibilityIcon from '@mui/icons-material/Accessibility'
import ArticleIcon from '@mui/icons-material/Article'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import AllergyTemplate from '../../templates/allergy-template/AllergyTemplate'
import BodyTemplate from '../../templates/body-template/BodyTemplate'
import FollowUpTemplate from '../../templates/follow-up-template/FollowUpTemplate'
import FormBuilderTemplate from '../../templates/form-builder-template/FormBuilderTemplate'
import HeightWeightTemplate from '../../templates/height-weight-template/HeightWeightTemplate'
import ImageMarkerTemplate from '../../templates/image-marker-template/ImageMarkerTemplate'
import ImageTemplate from '../../templates/image-template/ImageTemplate'
import ImmunizationTemplate from '../../templates/immunization-template/ImmunizationTemplate'
import MedicationTemplate from '../../templates/medication-template/MedicationTemplate'
import NoAccessTemplate from '../../templates/no-access-template/NoAccessTemplate'
import ReferralTemplate from '../../templates/referral-template/ReferralTemplate'
import SOAPTemplate from '../../templates/soap-template/SOAPTemplate'
import VitalsTemplate from '../../templates/vitals-template/VitalsTemplate'

export const NO_ACCESS_TEMPLATE = {
  historical: 'timeline',
  id: 'NoAccessTemplate',
  key: 'charting.templates.image.label',
  name: 'NoAccessTemplate',
  template: NoAccessTemplate,
}

export const TEMPLATES = {
  AllergyTemplate: {
    displayName: 'Allergy',
    historical: 'table',
    icon: <MedicalInformationIcon />,
    id: 'AllergyTemplate',
    key: 'charting.templates.allergy.label',
    name: 'AllergyTemplate',
    template: AllergyTemplate,
  },
  BodyTemplate: {
    displayName: 'Body',
    historical: 'timeline',
    icon: <AccessibilityIcon />,
    id: 'BodyTemplate',
    key: 'charting.templates.body.label',
    name: 'BodyTemplate',
    template: BodyTemplate,
  },
  FollowUpTemplate: {
    displayName: 'Follow Up',
    historical: 'table',
    icon: <AssignmentIcon />,
    id: 'FollowUpTemplate',
    key: 'charting.templates.follow-up.label',
    name: 'FollowUpTemplate',
    template: FollowUpTemplate,
  },
  FormBuilderTemplate: {
    displayName: 'Form Builder',
    historical: 'timeline',
    icon: <ArticleIcon />,
    id: 'FormBuilderTemplate',
    key: 'charting.templates.form-builder.label',
    name: 'FormBuilderTemplate',
    template: FormBuilderTemplate,
  },
  HeightWeightTemplate: {
    displayName: 'Height/Weight',
    historical: 'table',
    icon: <FitnessCenterIcon />,
    id: 'HeightWeightTemplate',
    key: 'charting.templates.height-weight.label',
    name: 'HeightWeightTemplate',
    template: HeightWeightTemplate,
  },
  ImageMarkerTemplate: {
    displayName: 'Image Marker',
    historical: 'timeline',
    icon: <ImageOutlinedIcon />,
    id: 'ImageMarkerTemplate',
    key: 'charting.templates.image-marker.label',
    name: 'ImageMarkerTemplate',
    template: ImageMarkerTemplate,
  },
  ImageTemplate: {
    displayName: 'Image',
    historical: 'timeline',
    icon: <ImageOutlinedIcon />,
    id: 'ImageTemplate',
    key: 'charting.templates.image.label',
    name: 'ImageTemplate',
    template: ImageTemplate,
  },
  ImmunizationTemplate: {
    displayName: 'Immunization',
    historical: 'table',
    icon: <VaccinesIcon />,
    id: 'ImmunizationTemplate',
    key: 'charting.templates.immunization.label',
    name: 'ImmunizationTemplate',
    template: ImmunizationTemplate,
  },
  MedicationTemplate: {
    displayName: 'Medication',
    historical: 'table',
    icon: <MonitorHeartOutlinedIcon />,
    id: 'MedicationTemplate',
    key: 'charting.templates.medication.label',
    name: 'MedicationTemplate',
    template: MedicationTemplate,
  },
  ReferralTemplate: {
    displayName: 'Referral',
    historical: 'table',
    icon: <PeopleOutlineIcon />,
    id: 'ReferralTemplate',
    key: 'charting.templates.referral.label',
    name: 'ReferralTemplate',
    template: ReferralTemplate,
  },
  SOAPTemplate: {
    displayName: 'SOAP',
    historical: 'table',
    icon: <SubjectOutlinedIcon />,
    id: 'SOAPTemplate',
    key: 'charting.templates.soap.label',
    name: 'SOAPTemplate',
    template: SOAPTemplate,
  },
  VitalsTemplate: {
    displayName: 'Vitals',
    historical: 'table',
    icon: <MonitorHeartOutlinedIcon />,
    id: 'VitalsTemplate',
    key: 'charting.templates.vitals.label',
    name: 'VitalsTemplate',
    template: VitalsTemplate,
  },
}

export default TEMPLATES
