import './ClientRecordHeader.scss'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Chip, Grid, Stack, Typography } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import changeClientContext from 'assets/images/changeClientContext.svg'
import clientHeaderSrc from 'assets/images/client-header-icon.svg'
import groupHeaderSrc from 'assets/images/group-header-icon.svg'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISChip from 'common/components/form/MISChip'
import MISButton from 'common/components/MISButton'
import global from 'common/styles/global.scss'
import {
  isDateAfterToday,
  isDateBeforeOrEqualToday,
  isoDateToDisplayFormat,
} from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import { getClientAgeString, getClientFullName } from 'modules/shared/clientUtils'
import {
  chartingAttendeesProgressState,
  chartingEditorProgressState,
  chartingFieldsProgressState,
  isDisplayInProgressState,
  navigateFromMenuIdState,
} from 'recoil/charting'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientControllerService,
  ClientDTO,
  ClientIdentifier,
  ClientSearchResultDTO,
  CodedRef,
  GroupDTO,
  PrivacyDirectiveControllerService,
} from 'services/openapi'
import {
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MEMBER_STATUS_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
  VALUESET,
} from 'services/terminologyConstants'
import { setChartingClients } from 'store/reducers/charting'
import { setClientPrivacyDirectives } from 'store/reducers/client'
import { selectClientPrivacyDirectives } from 'store/selectors/client'
import PrivacyDirectivesPopover from './ClientPrivacyDirectives/PrivacyDirectivesPopover'

type SearchResultType = {
  id: string
  type: string
  displayName: string
}

type ClientRecordHeaderDetailsProps = {
  client?: ClientDTO
  allowChangeContext?: boolean
  group?: GroupDTO
  readOnly?: boolean
  withNavigation?: boolean
}

const ClientRecordHeaderDetails = ({
  allowChangeContext = false,
  client,
  group,
  readOnly,
  withNavigation,
}: ClientRecordHeaderDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const clientPrivacyDirectives = useSelector(selectClientPrivacyDirectives)
  const clientStatusOptions = useRecoilValue(terminologySelector(MEMBER_STATUS_VOCAB_NAME))
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const identifierTypeOptions = useRecoilValue(
    terminologySelector(MEMBER_IDENTIFIER_TYPE_VOCAB_NAME)
  )

  const [, setSearchText] = useState('')
  const [displayChangeContext, setDisplayChangeContext] = useState(false)
  const [searchResults, setSearchResults] = useState<SearchResultType[]>([])
  const [, setSelectedClientId] = useState<string>('')
  const [, setSelectedGroupId] = useState<string>('')
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)
  const setIsDisplayInProgress = useSetRecoilState(isDisplayInProgressState)
  const setChartingEditorProgress = useSetRecoilState(chartingEditorProgressState)
  const setNavigateFromMenuId = useSetRecoilState(navigateFromMenuIdState)
  const setChartingAttendeesProgress = useSetRecoilState(chartingAttendeesProgressState)
  const setChartingFieldsProgress = useSetRecoilState(chartingFieldsProgressState)

  const getStatus = useCallback(
    (status: CodedRef) => {
      return clientStatusOptions.find((option) => status?.code === option.code)?.name
    },
    [clientStatusOptions]
  )

  const getIdentifierValue = useCallback(
    (identifierKey: string) => {
      const identifier =
        identifierTypeOptions?.find((option) => option.name === identifierKey) || null

      return (
        identifier &&
        client &&
        client.clientIdentifiers?.find(
          (obj: ClientIdentifier) =>
            obj.type?.code === identifier.code &&
            obj.type?.codeSystemOid === identifier.codeSystemOid
        )?.value
      )
    },
    [client, identifierTypeOptions]
  )

  useEffect(() => {
    const getClientPrivacyDirectives = async (clientId: string) => {
      try {
        const directives = await PrivacyDirectiveControllerService.getDirectives(
          clientId,
          true,
          undefined,
          undefined,
          ['auditInfo']
        )
        if (!directives?.content?.length) {
          dispatch(setClientPrivacyDirectives(undefined))
          return
        }
        const directive = directives.content[0]
        dispatch(setClientPrivacyDirectives(directive))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (client && client.id && !readOnly) getClientPrivacyDirectives(client.id)
  }, [client, dispatch, handleApiError, readOnly])

  const getClientDetail = useCallback(
    (searchResult: ClientSearchResultDTO | undefined) => {
      if (searchResult) {
        const { firstName, lastName } = searchResult
        const preferredName = [firstName, lastName].filter(Boolean).join(' ').trim()

        const gender =
          searchResult.gender &&
          genderOptions?.find((option) => option.code === searchResult.gender?.code)?.name
        const birthDate = searchResult.birthdate
          ? isoDateToDisplayFormat(searchResult.birthdate)
          : ''
        const age = searchResult.birthdate ? getClientAgeString(searchResult.birthdate) : ''
        return `${preferredName}|${t('client-header.gender-label')}: ${gender}| ${t(
          'client-header.birthdate-label'
        )}:${birthDate}|${t('client-header.age-label')}: ${age} `
      }
    },
    [genderOptions, t]
  )

  const handleSearch = useCallback(
    async (searchText: string) => {
      if (searchText && searchText.length >= 3) {
        try {
          const response = await ClientControllerService.getHeaderSearchResult(searchText)
          const results = response.map((result) => {
            const searchResp = {
              ...JSON.parse(JSON.stringify(result)),
            }
            return {
              displayName: result.type === 'GROUP' ? searchResp.name : getClientDetail(searchResp),
              id: result.id || '',
              type: result.type || '',
            }
          })
          setSearchResults(results)
        } catch (err) {
          setSearchResults([])
        }
      } else {
        setSearchResults([])
      }
    },
    [getClientDetail]
  )

  const handleChangeContextClick = useCallback(() => {
    setDisplayChangeContext(true)
  }, [])

  const handleCancelClick = useCallback(() => {
    setDisplayChangeContext(false)
  }, [])

  const handleSearchInputChange = useCallback(
    (value: string) => {
      setSearchText(value)
      handleSearch(value)
    },
    [handleSearch]
  )

  const performGroupNavigation = useCallback(
    (groupId: string) => {
      switch (seletedMenuItem) {
        case 'navigation.left-nav-menu.charting.blank-canvas': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/groups/group-record/${groupId}/charting/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.group.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/groups/group-record/${groupId}/charting/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.client.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/groups/group-record/${groupId}/charting/in-progress`)
          break
        }
        default: {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting')
          setIsDisplayInProgress(false)
          navigate(`/groups/group-record/${groupId}/charting`)
        }
      }
    },
    [navigate, setIsDisplayInProgress, setSeletedMenuItem, seletedMenuItem]
  )

  const performClientNavigation = useCallback(
    (clientId: string) => {
      switch (seletedMenuItem) {
        case 'navigation.left-nav-menu.charting.blank-canvas': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/clients/client-record/${clientId}/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.client.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/clients/client-record/${clientId}/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.group.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/clients/client-record/${clientId}/in-progress`)
          break
        }
        default: {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          setIsDisplayInProgress(false)
          navigate(`/clients/client-record/${clientId}/charting`)
        }
      }
    },
    [navigate, setIsDisplayInProgress, setSeletedMenuItem, seletedMenuItem]
  )

  const handleChangeContext = useCallback(
    (value: any) => {
      if (seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas') {
        setIsDisplayInProgress(false)
        setChartingEditorProgress(undefined)
        setChartingFieldsProgress(undefined)
      }
      setChartingAttendeesProgress(undefined)
      dispatch(setChartingClients(undefined))
      setNavigateFromMenuId(seletedMenuItem)
      if (value.type === 'CLIENT') {
        performClientNavigation(value.id)
        setSelectedGroupId('')
      } else {
        performGroupNavigation(value.id)
        setSelectedClientId('')
      }
      setDisplayChangeContext(false)
    },
    [
      dispatch,
      performClientNavigation,
      performGroupNavigation,
      setChartingAttendeesProgress,
      setChartingEditorProgress,
      setChartingFieldsProgress,
      setIsDisplayInProgress,
      setNavigateFromMenuId,
      seletedMenuItem,
    ]
  )

  const getSearchContextField = useCallback(() => {
    return (
      (displayChangeContext ||
        seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas') && (
        <Grid container spacing={2} sx={{ marginLeft: '10px', marginTop: 0 }}>
          <Grid item xs={10}>
            <MISAutocomplete
              label={t('client-header.search-field-label')}
              onChange={(value) => handleChangeContext(value)}
              onInputChange={handleSearchInputChange}
              options={searchResults.map((result) => {
                return { id: result.id, label: result.displayName, type: result.type }
              })}
            />
          </Grid>
          <Grid item xs={2}>
            {seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
              <MISButton
                color="secondary"
                onClick={handleCancelClick}
                sx={{ marginLeft: 1 }}
                variant="outlined"
              >
                {t('common.button.cancel')}
              </MISButton>
            )}
          </Grid>
        </Grid>
      )
    )
  }, [
    displayChangeContext,
    handleCancelClick,
    handleChangeContext,
    handleSearchInputChange,
    searchResults,
    seletedMenuItem,
    t,
  ])

  const isGroupActive = useCallback(() => {
    if (group && group.effective?.startDate) {
      const isActive =
        isDateBeforeOrEqualToday(group.effective.startDate) &&
        (!group?.effective.endDate || isDateAfterToday(group?.effective.endDate))

      return isActive
    }
  }, [group])

  const getHeaderStatusChip = useCallback(
    (pillColor: string, circleColor: string, label: string) => {
      return (
        <MISChip
          avatar={<Avatar />}
          label={label}
          sx={{
            '& .MuiAvatar-fallback': {
              visibility: 'hidden',
            },
            '& .MuiChip-avatar': {
              backgroundColor: circleColor,
              height: 8,
              width: 8,
            },
            backgroundColor: pillColor,
            color: 'black',
            mx: 0.25,
          }}
        />
      )
    },
    []
  )

  const getGroupStatusChip = useCallback(() => {
    const label = isGroupActive() ? 'Active' : 'Inactive'
    let circleColor = ''
    let pillColor = ''
    switch (label) {
      case 'Active': {
        circleColor = global.CHIP_HEADER_ACTIVE_PILL_COLOR
        pillColor = global.CHIP_HEADER_ACTIVE_BG_COLOR
        break
      }
      default:
        circleColor = global.CHIP_HEADER_INACTIVE_PILL_COLOR
        pillColor = global.CHIP_HEADER_INACTIVE_BG_COLOR
        break
    }
    return getHeaderStatusChip(pillColor, circleColor, label)
  }, [getHeaderStatusChip, isGroupActive])

  const getGroupContextHeader = useCallback(() => {
    return (
      !displayChangeContext &&
      group &&
      seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
        <Box className="group-header">
          <Box className="title-panel">
            <Box
              sx={{ '& .MuiAvatar-root': { backgroundColor: global.HEADER_ICON_BACKGROUND_COLOR } }}
            >
              <Avatar
                alt="Avatar Image"
                className="avatar"
                src={groupHeaderSrc}
                sx={{
                  '.MuiAvatar-img': { height: '15px', width: '30px' },
                }}
              >
                <PersonIcon className="icon" />
              </Avatar>
            </Box>
            <Typography className="title">{group?.name}</Typography>
            {allowChangeContext && (
              <img
                alt="change context"
                className="change-icon"
                onClick={handleChangeContextClick}
                src={changeClientContext}
              />
            )}
            <Typography className="details">{group?.description}</Typography>
            {getGroupStatusChip()}
          </Box>
        </Box>
      )
    )
  }, [
    allowChangeContext,
    getGroupStatusChip,
    handleChangeContextClick,
    group,
    displayChangeContext,
    seletedMenuItem,
  ])

  const isDisplayClientHeader = useCallback(() => {
    return (
      !displayChangeContext &&
      !group &&
      seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas'
    )
  }, [group, displayChangeContext, seletedMenuItem])

  const getStatusChip = useCallback(() => {
    if (client?.status && client.status?.code) {
      const statusCode = client.status?.code
      let circleColor = ''
      let pillColor = ''
      switch (statusCode) {
        case VALUESET.MEMBER_STATUS.ACTIVE.code: {
          circleColor = global.CHIP_HEADER_ACTIVE_PILL_COLOR
          pillColor = global.CHIP_HEADER_ACTIVE_BG_COLOR
          break
        }
        default:
          circleColor = global.CHIP_HEADER_INACTIVE_PILL_COLOR
          pillColor = global.CHIP_HEADER_INACTIVE_BG_COLOR
          break
      }
      return getHeaderStatusChip(pillColor, circleColor, getStatus(client.status) || '')
    }
  }, [client?.status, getHeaderStatusChip, getStatus])

  return (
    <Stack
      sx={{
        pb: 1,
        pt: 1,
      }}
    >
      {getSearchContextField()}
      {getGroupContextHeader()}

      {isDisplayClientHeader() && (
        <Box className="client-header">
          <Box
            className="panelLeft"
            sx={{ '& .MuiAvatar-root': { backgroundColor: global.HEADER_ICON_BACKGROUND_COLOR } }}
          >
            <Avatar
              alt="Avatar Image"
              className="avatar"
              src={clientHeaderSrc}
              sx={{
                '.MuiAvatar-img': { height: '24px', width: '24px' },
              }}
            >
              <PersonIcon className="icon" />
            </Avatar>
          </Box>
          <Box className="panelRight">
            <Stack>
              <Box style={{ alignItems: 'center', display: 'flex' }}>
                <Typography className="title">{getClientFullName(client)}</Typography>
                {allowChangeContext && (
                  <img
                    alt="change context"
                    className="change-icon"
                    onClick={handleChangeContextClick}
                    src={changeClientContext}
                  />
                )}
                {clientPrivacyDirectives &&
                  clientPrivacyDirectives?.directive?.code === 'RESTRICTED' && (
                    <Chip label="Restricted" sx={{ mx: 0.25, padding: 0 }} />
                  )}
                {!readOnly && <PrivacyDirectivesPopover />}
                <Typography className="details">
                  <span>{t('client-header.gender-label')}: </span>
                  {client?.gender &&
                    genderOptions?.find((option) => option.code === client.gender?.code)?.name}
                  <span className="seperator">| </span>
                </Typography>

                <Typography className="details">
                  <span>{t('client-header.birthdate-label')}: </span>
                  {client?.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''}
                  <span className="seperator">| </span>
                </Typography>
                {!client?.deathDate && (
                  <Typography className="details">
                    <span>{t('client-header.age-label')}: </span>
                    {client?.birthdate ? getClientAgeString(client.birthdate) : ''}
                    <span className="seperator">| </span>
                  </Typography>
                )}
                {client?.deathDate && (
                  <Typography className="details">
                    <span>{t('client-header.age-at-death-label')}: </span>
                    {client.birthdate ? getClientAgeString(client.birthdate, client.deathDate) : ''}
                    <span className="seperator">| </span>
                  </Typography>
                )}
                <Typography className="details">
                  <span>{t('client-header.status-card-label')}: </span>
                  {getIdentifierValue('Indian Registry Status Number')}
                </Typography>
                <span style={{ marginLeft: global.MARGIN_XXS }}>{getStatusChip()}</span>
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </Stack>
  )
}

export default ClientRecordHeaderDetails
