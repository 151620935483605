import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LastPageIcon from '@mui/icons-material/LastPage'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { visuallyHidden } from '@mui/utils'
import GLOBAL from 'common/styles/global.scss'
import MISCheckbox from '../form/MISCheckbox'

export type PaginationProps = {
  order: Order
  orderBy: string | number | symbol
  page: number
  rowsPerPage: number
}

export interface EmptyTableProps {
  isEmpty: boolean
  emptyNode?: ReactNode | null
}

interface MISTableProps<T> {
  headers: Header[]
  data: T[]
  renderRow: (row: T, index: number) => ReactNode
  footer?: ReactNode
  aboveTable?: ReactNode
  displayTableHeader?: boolean
  order?: Order
  orderBy?: string
  tableRef?: React.RefObject<HTMLTableElement>
  title?: string
  totalElements?: number
  emptySituation?: EmptyTableProps
  updatePagination?: (pagination: PaginationProps) => void
}

type Order = 'asc' | 'desc'

export interface EnhancedTableProps {
  checkBoxEnabled?: boolean
  headers: Header[]
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string | number | symbol
  rowCount: number
}

export type Header = {
  id: string
  label: string
  numeric?: boolean
  disablePadding?: boolean
  width?: string
  translated?: boolean
  displayNone?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    checkBoxEnabled,
    headers,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
  } = props
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  const { t } = useTranslation('common')

  return (
    <TableHead>
      <TableRow>
        {checkBoxEnabled && (
          <TableCell padding="checkbox">
            <MISCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headers.map((headCell: any) => (
          <TableCell
            align={headCell.numeric ? 'right' : 'left'}
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: 'rgba(0, 0, 0, 0.5)',
              display: headCell.displayNone ? 'none' : 'table-cell',
            }}
            width={headCell.width ? headCell.width : 'auto'}
          >
            {!headCell.tooltipText && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.translated ? t(headCell.label) : headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {headCell.tooltipText && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {headCell.label}
                <Tooltip arrow placement="top" title={headCell.tooltipText}>
                  <InfoOutlinedIcon
                    sx={{
                      color: `${GLOBAL.BUTTON_PRIMARY_BG_COLOR}`,
                      cursor: 'pointer',
                      mr: 1,
                    }}
                  />
                </Tooltip>
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, onPageChange, page, rowsPerPage } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const MISTable = <T,>({
  aboveTable,
  data,
  displayTableHeader = true,
  emptySituation,
  footer,
  headers,
  order = 'asc',
  orderBy = '',
  renderRow,
  tableRef,
  title,
  totalElements,
  updatePagination,
}: MISTableProps<T>): JSX.Element => {
  const [selected, setSelected] = useState<readonly string[]>([])
  const [pagination, setPagination] = useState<PaginationProps>({
    order,
    orderBy,
    page: 0,
    rowsPerPage: 10,
  })

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = pagination.orderBy === property && pagination.order === 'asc'
    setPagination({ ...pagination, order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ ...pagination, page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  }

  useEffect(() => {
    if (updatePagination) {
      updatePagination(pagination)
    }
  }, [pagination, updatePagination])

  return (
    <Box>
      {title && (
        <Typography sx={{ flex: '1 1 100%', p: 2 }} variant="h6">
          {title}
        </Typography>
      )}
      <TableContainer className="table-container" sx={{ p: 2 }}>
        {aboveTable && <>{aboveTable}</>}
        <Table
          className="table"
          ref={tableRef}
          sx={{ '& .MuiTableCell-root': { borderBottomColor: '#eee' }, tableLayout: 'fixed' }}
        >
          {displayTableHeader && (
            <EnhancedTableHead
              headers={headers}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              order={pagination.order}
              orderBy={pagination.orderBy}
              rowCount={data.length}
            />
          )}
          {!emptySituation?.isEmpty && (
            <TableBody
              sx={{
                '& .MuiTableCell-root .MuiTypography-root': {
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%',
                },
                minWidth: 650,
                size: 'small',
              }}
            >
              {data.map((row, rowIndex) => {
                if (renderRow) {
                  return renderRow(row, rowIndex)
                } else {
                  return null
                }
              })}
            </TableBody>
          )}
          <TableFooter className="footer">
            {footer}
            {updatePagination && (
              <TablePagination
                ActionsComponent={TablePaginationActions}
                count={totalElements ?? data.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                slotProps={{
                  select: { inputProps: { 'aria-label': 'rows per page' }, native: true },
                }}
              />
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default MISTable
