import { ReactNode } from 'react'
import { Paper, Stack, Typography } from '@mui/material'

type StatusCardContainerProps = {
  children: ReactNode
  icon: JSX.Element
  label: string
}

const StatusCardContainer = ({ children, icon, label }: StatusCardContainerProps) => {
  return (
    <Paper sx={{ backgroundColor: '#fff', minHeight: '220px', p: 2, width: '19%' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 1 }}>
        <Stack direction="row">
          {icon}
          <Typography sx={{ color: '#333', fontSize: '14px', fontWeight: 700, lineHeight: '16px' }}>
            {label}
          </Typography>
        </Stack>
      </Stack>
      {children}
    </Paper>
  )
}

export default StatusCardContainer
