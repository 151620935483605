import { ChangeEvent, ElementType, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Box, Grid, InputBaseComponentProps, SelectChangeEvent, Stack } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { CodedConceptDto } from 'services/openapi'
import {
  MIS_LENGTH_UNITS,
  MIS_MILEAGE_UNITS,
  MIS_TEMPERATURE_UNITS,
  MIS_TIME_UNITS,
  MIS_WEIGHT_UNITS,
} from 'services/terminologyConstants'
import { selectTerminology } from 'store/selectors/terminology'
import ConfigurationContainer from './ConfigurationContainer'
import EditButton from './EditButton'
import { EFormBuilderUnitType, TFormBuilderComponentData } from '../FormBuilderTemplate'

type FBValueUnitProps = {
  data?: TFormBuilderComponentData
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeUnitValue: (e: SelectChangeEvent<string>) => void
  onDelete: () => void
  onSave: (data: TFormBuilderComponentData) => void
  preview: boolean
}

const FBValueUnit = ({
  data,
  onChangeUnitValue,
  onChangeValue,
  onDelete,
  onSave,
  preview,
}: FBValueUnitProps) => {
  const { t } = useTranslation('common')
  const terminologySets = useSelector(selectTerminology)

  const [configData, setConfigData] = useState<TFormBuilderComponentData>(data || {})
  const [configMode, setConfigMode] = useState(false)
  const [unitOpts, setUnitOpts] = useState<CodedConceptDto[]>([])

  const getComponent = useCallback(
    () => (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <MISTextField
            {...data}
            InputProps={{
              inputComponent: NumericFormat as ElementType<InputBaseComponentProps>,
              inputProps: { allowLeadingZeros: false },
            }}
            onChange={onChangeValue}
          />
        </Grid>
        <Grid item xs={4}>
          <MISSelectDropdown
            label={t('charting.form-builder.value-unit.unit')}
            noClearIcon
            onChange={(e) => onChangeUnitValue(e)}
            options={unitOpts.map((each) => ({
              label: each.name as string,
              value: each,
            }))}
            value={data?.unitValue}
          />
        </Grid>
      </Grid>
    ),
    [data, onChangeValue, onChangeUnitValue, t, unitOpts]
  )

  useEffect(() => {
    if (!data?.unitType) return
    let opts
    switch (data.unitType) {
      case EFormBuilderUnitType.LENGTH:
        opts = terminologySets.find((set) => set.setName === MIS_LENGTH_UNITS)?.value || []
        setUnitOpts(opts)
        break
      case EFormBuilderUnitType.MILEAGE:
        opts = terminologySets.find((set) => set.setName === MIS_MILEAGE_UNITS)?.value || []
        setUnitOpts(opts)
        break
      case EFormBuilderUnitType.TEMPERATURE:
        opts = terminologySets.find((set) => set.setName === MIS_TEMPERATURE_UNITS)?.value || []
        setUnitOpts(opts)
        break
      case EFormBuilderUnitType.TIME:
        opts = terminologySets.find((set) => set.setName === MIS_TIME_UNITS)?.value || []
        setUnitOpts(opts)
        break
      case EFormBuilderUnitType.WEIGHT:
        opts = terminologySets.find((set) => set.setName === MIS_WEIGHT_UNITS)?.value || []
        setUnitOpts(opts)
        break
      default:
        break
    }
  }, [data, terminologySets])

  return (
    <>
      {preview ? (
        <>{getComponent()}</>
      ) : (
        <Box onDoubleClick={() => setConfigMode(true)} sx={{ height: '100%', width: '100%' }}>
          <Box sx={{ mr: 4 }}>{getComponent()}</Box>
          <EditButton onDelete={onDelete} onEdit={() => setConfigMode(true)} />
          <ConfigurationContainer
            onCancel={() => setConfigMode(false)}
            onSave={() => {
              onSave(configData)
              setConfigMode(false)
            }}
            open={configMode}
            title={t('charting.form-builder.text-field.title')}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <MISTextField
                defaultValue={data?.label}
                label={t('charting.form-builder.label')}
                onChange={(e) =>
                  setConfigData(
                    configData
                      ? { ...configData, label: e.target.value }
                      : { label: e.target.value }
                  )
                }
              />
              <MISSelectDropdown
                defaultValue="Mileage"
                label={t('charting.form-builder.value-unit.unit-of-measure')}
                onChange={(e) => setConfigData({ ...configData, unitType: e.target.value })}
                options={Object.values(EFormBuilderUnitType).map((each) => ({
                  label: each,
                  value: each,
                }))}
                value={configData?.unitType}
              />
            </Stack>
          </ConfigurationContainer>
        </Box>
      )}
    </>
  )
}

export default FBValueUnit
