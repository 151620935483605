/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicationTerse } from '../models/MedicationTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MedicationControllerService {
  /**
   * Search Medication products by name
   * @param nameQuery
   * @returns MedicationTerse OK
   * @throws ApiError
   */
  public static searchMedicationsByName(
    nameQuery?: string
  ): CancelablePromise<Array<MedicationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/medications',
      query: {
        nameQuery: nameQuery,
      },
    })
  }
}
