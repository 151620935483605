import { Component } from 'react'
import { dateNowIsoString } from 'common/utils/DateUtils'
import { CodedConceptDto, MedicationTerse } from 'services/openapi'
import MedicationTemplateComponent from './MedicationTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type TMedicationTemplateValue = boolean | string | CodedConceptDto | undefined

export type MedicationTemplateState = {
  name: string
  strength?: string
  strengthNum?: string
  strengthUnit?: CodedConceptDto
  customIndicator?: boolean
  prescriber?: CodedConceptDto
  source?: CodedConceptDto
  prn?: boolean
  medication: MedicationTerse
  medicationLength?: string
  dosageNotes?: string
  route?: CodedConceptDto
  reasonForUse?: string
  startDate?: string
  internalNotes?: string
  lastReviewedBy?: string
  lastReviewedOn?: string
  createdBy?: string
  createdOn?: string
}

export type MedicationTemplateProps = { data: MedicationTemplateState }

class MedicationTemplate
  extends Component<MedicationTemplateProps, MedicationTemplateState>
  implements ITemplate
{
  type = 'MedicationTemplate'
  state: MedicationTemplateState

  constructor(props: MedicationTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    customIndicator: { label: 'Indicator', type: ETemplateMetadataType.boolean },
    dosageNotes: { label: 'Dosage notes', type: ETemplateMetadataType.string },
    internalNotes: { label: 'Notes', type: ETemplateMetadataType.string },
    lastReviewedBy: { label: 'Last reviewed by', type: ETemplateMetadataType.string },
    lastReviewedOn: { label: 'Last reviewed on', type: ETemplateMetadataType.string },
    medicationLength: { label: 'Term', type: ETemplateMetadataType.string },
    name: { label: 'Name', required: true, type: ETemplateMetadataType.string },
    prescriber: { label: 'Prescriber', type: ETemplateMetadataType.CodedConceptDto },
    prn: { label: 'PRN', type: ETemplateMetadataType.boolean },
    reasonForUse: { label: 'Reason for use', type: ETemplateMetadataType.string },
    route: { label: 'Route', type: ETemplateMetadataType.CodedConceptDto },
    source: { label: 'Source', type: ETemplateMetadataType.CodedConceptDto },
    startDate: { label: 'Start date', type: ETemplateMetadataType.string },
    strength: { label: 'Strength', type: ETemplateMetadataType.string },
    strengthUnit: { label: 'Strength Units', type: ETemplateMetadataType.CodedConceptDto },
  })

  static getTemplateDisplayName = (): string => 'Medication Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    customIndicator: this.state.customIndicator,
    dosageNotes: this.state.dosageNotes,
    internalNotes: this.state.internalNotes,
    lastReviewedBy: this.state.lastReviewedBy,
    lastReviewedOn: this.state.lastReviewedOn,
    medicationLength: this.state.medicationLength,
    name: this.state.name,
    prescriber: this.state.prescriber,
    prn: this.state.prn,
    reasonForUse: this.state.reasonForUse,
    route: this.state.route,
    source: this.state.source,
    startDate: this.state.startDate,
    strength: this.state.strength,
    strengthNum: this.state.strengthNum,
    strengthUnit: this.state.strengthUnit,
  })

  getEmptyData = () => ({
    createdBy: '',
    createdOn: '',
    customIndicator: undefined,
    dosageNotes: undefined,
    internalNotes: undefined,
    lastReviewedBy: undefined,
    lastReviewedOn: undefined,
    medicationLength: undefined,
    name: undefined,
    prescriber: undefined,
    prn: undefined,
    reasonForUse: undefined,
    route: undefined,
    source: undefined,
    startDate: undefined,
    strength: undefined,
    strengthNum: undefined,
    strengthUnit: undefined,
  })

  handleChange = (key: string, value: TMedicationTemplateValue) => {
    this.setState({ ...this.state, [key]: value, lastReviewedOn: dateNowIsoString() })
  }

  save = async () => {
    console.log('Save Medication Template Called', this.type)
  }

  cancel = async () => {
    console.log('Cancel Medication Template Called', this.type)
  }

  render() {
    return <MedicationTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default MedicationTemplate
