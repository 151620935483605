import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TrendingUpRounded } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
} from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT } from 'common/utils/DateUtils'
import MISTable from '../table/MISTable'

const columns = [
  {
    disablePadding: true,
    id: 'name',
    isSortable: true,
    label: 'types.table-header.name',
    translated: true,
  },
  {
    id: 'startDate',
    isSortable: true,
    label: 'types.table-header.start-date',
    translated: true,
  },
  {
    id: 'endDate',
    isSortable: true,
    label: 'types.table-header.end-date',
    translated: true,
  },
  {
    id: 'select',
    isSortable: false,
    label: 'types.table-header.link',
    translated: true,
  },
]

export default function AddAssociationDialog(props) {
  const { t } = useTranslation('common')

  const { entityName, handleCloseCallback, onSaveClickedCallback, openDialog, otherEntities } =
    props
  const [associations, setAssociations] = useState([])
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const handleClose = () => {
    handleCloseCallback()
  }

  const handleStartDateChange = useCallback(
    (value, index) => {
      let updatedAssociation = {
        ...associations[index],
        isSelected: TrendingUpRounded,
        startDate: value,
      }
      let associationsCopy = [...associations]
      associationsCopy[index] = updatedAssociation
      setAssociations(associationsCopy)
    },
    [associations]
  )

  const handleEndDateChange = useCallback(
    (value, index) => {
      let updatedAssociation = { ...associations[index], endDate: value, isSelected: true }
      let associationsCopy = [...associations]
      associationsCopy[index] = updatedAssociation
      setAssociations(associationsCopy)
    },
    [associations]
  )

  const handleCheckboxChange = useCallback(
    (event, index) => {
      let updatedAssociation = { ...associations[index], isSelected: event.target.checked }
      let associationsCopy = [...associations]
      associationsCopy[index] = updatedAssociation
      setAssociations(associationsCopy)
    },
    [associations]
  )

  const getEndDateHelperText = useCallback(
    (startDate, endDate) => {
      if (endDate && !endDate.isValid) {
        return t('common.helper-text.must-be-valid-date', { fieldName: 'End date' })
      } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
        return t('common.helper-text.start-date-before-end-date')
      }
      return ''
    },
    [t]
  )

  const getStartDateHelperText = useCallback(
    (startDate, endDate) => {
      if (!startDate) {
        return t('common.helper-text.required-field', { fieldName: 'Start date' })
      } else if (startDate && !startDate.isValid) {
        return t('common.helper-text.must-be-valid-date', { fieldName: 'Start date' })
      } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
        return t('common.helper-text.start-date-before-end-date')
      }
      return ''
    },
    [t]
  )

  const isStartDateValid = useCallback((startDate, endDate) => {
    if (!startDate || !startDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }, [])

  const isEndDateValid = useCallback((startDate, endDate) => {
    if (endDate && !endDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }, [])

  const validateAssociations = useCallback(() => {
    let isValid = true
    associations.forEach((association) => {
      if (association.isSelected) {
        if (
          !isStartDateValid(association.startDate, association.endDate) ||
          !isEndDateValid(association.startDate, association.endDate)
        ) {
          isValid = false
        }
      }
    })
    return isValid
  }, [associations, isEndDateValid, isStartDateValid])

  const handleSaveClicked = useCallback(() => {
    setShowValidationErrors(true)
    if (!validateAssociations()) {
      return
    } else {
      setShowValidationErrors(false)
    }
    let associationsToSave = []
    associations.forEach((association) => {
      if (association.isSelected) {
        let associationToSave = {
          endDate: association.endDate ? association.endDate.toISO() : null,
          otherEntityId: association.otherEntityId,
          startDate: association.startDate ? association.startDate.toISO() : null,
        }
        associationsToSave.push(associationToSave)
      }
    })
    onSaveClickedCallback(associationsToSave)
  }, [associations, onSaveClickedCallback, validateAssociations])

  const renderTableRow = useCallback(
    (association, index) => {
      return (
        <TableRow hover key={association.otherEntityId} tabIndex={-1}>
          <TableCell
            component="th"
            id={`enhanced-table-checkbox-${index}`}
            padding="none"
            scope="row"
            style={{ maxWidth: 300 }}
          >
            {association.otherEntityName}
          </TableCell>

          <TableCell align="left">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                format={IDHE_DATE_DISPLAY_FORMAT}
                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                label={t('types.table-header.start-date')}
                mask="____-__-__"
                onChange={(value) => handleStartDateChange(value, index)}
                renderInput={(params) => (
                  <MISTextField
                    {...params}
                    error={
                      showValidationErrors &&
                      association.isSelected &&
                      !isStartDateValid(association.startDate, association.endDate)
                    }
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      association.isSelected &&
                      getStartDateHelperText(association.startDate, association.endDate)
                    }
                  />
                )}
                value={association.startDate}
              />
            </LocalizationProvider>
          </TableCell>
          <TableCell align="left">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                format={IDHE_DATE_DISPLAY_FORMAT}
                inputFormat={IDHE_DATE_DISPLAY_FORMAT}
                label={t('types.table-header.end-date')}
                mask="____-__-__"
                onChange={(value) => handleEndDateChange(value, index)}
                renderInput={(params) => (
                  <MISTextField
                    {...params}
                    error={
                      showValidationErrors &&
                      association.isSelected &&
                      !isEndDateValid(association.startDate, association.endDate)
                    }
                    fullWidth
                    helperText={
                      showValidationErrors &&
                      association.isSelected &&
                      getEndDateHelperText(association.startDate, association.endDate)
                    }
                  />
                )}
                value={association.endDate}
              />
            </LocalizationProvider>
          </TableCell>
          <TableCell align="left">
            <MISCheckbox
              checked={association.isSelected}
              onChange={(event) => handleCheckboxChange(event, index)}
            />
          </TableCell>
        </TableRow>
      )
    },
    [
      getEndDateHelperText,
      getStartDateHelperText,
      handleCheckboxChange,
      handleEndDateChange,
      handleStartDateChange,
      isEndDateValid,
      isStartDateValid,
      showValidationErrors,
      t,
    ]
  )

  useEffect(() => {
    let emptyAssociations = []
    otherEntities.forEach((e) => {
      let association = {
        endDate: null,
        isSelected: false,
        otherEntityId: e.id,
        otherEntityName: e.name,
        startDate: null,
      }
      emptyAssociations.push(association)
    })
    setAssociations(emptyAssociations)
  }, [otherEntities])

  return (
    <div>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} open={openDialog}>
        <DialogTitle>Link to {entityName}</DialogTitle>
        <DialogContent>
          <MISTable data={associations} headers={columns} renderRow={renderTableRow} />
        </DialogContent>
        <DialogActions>
          <MISButton onClick={handleClose}>{t('common.button.cancel')}</MISButton>
          <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
