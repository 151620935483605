import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import Loader from 'common/components/Loader'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import ClientInfo from './components/ClientInfo/ClientInfo'
import ClientStatus from './components/ClientStatus/ClientStatus'
import ClientRecordHeader from '../ClientDetails/ClientRecordHeader'
import ClientStartup from '../ClientDetails/ClientStartup'

const ClientDashboard = () => {
  const { clientId } = useParams()
  const { clientDetails } = useClientDetails(clientId)

  return (
    <>
      {clientDetails?.id ? (
        <>
          <ClientStartup clientId={clientDetails.id} />
          <ClientRecordHeader />
          <Grid container spacing={4} sx={{ ml: -6, mt: -2 }}>
            <Grid item xs={4}>
              <ClientInfo />
            </Grid>
            <Grid item xs={8}>
              <ClientStatus />
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader fullScreen />
      )}
    </>
  )
}

export default ClientDashboard
