import { useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import loginBgImage from 'assets/images/login-artwork1.jpg'
import logoImg from 'assets/images/login-logo.png'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import global from 'common/styles/global.scss'
import { UserControllerService } from 'services/openapi'

const InitialUsernamePage = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault()
      if (!username) {
        setError('Username is required')
        return
      }

      try {
        const res = await UserControllerService.findTenantByUsername(username.trim())
        if (res) {
          onLogin(res, username)
        }
      } catch (err) {
        setError('Username not found')
      }
    },
    [onLogin, username]
  )

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleLogin(e)
      }
    },
    [handleLogin]
  )

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item md={6} xs={12}>
        <Box
          style={{
            backgroundImage: ` url(${logoImg}),url(${loginBgImage})`,
            backgroundPosition: '30px 30px, center',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: '158px 50px, cover',
            height: '100%',
            position: 'relative',
          }}
        />
      </Grid>

      <Grid alignItems="center" display="flex" item justifyContent="center" md={6} xs={12}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: global.WHITE,
              border: `1px solid ${global.LOGIN_BOX_BORDER_COLOR}`,
              borderRadius: `calc(${global.BASE_UNIT}  * 1.5)`,
              display: 'flex',
              flexDirection: 'column',
              padding: '24px',
              width: '382px',
            }}
          >
            <Typography
              color="black"
              component="h1"
              fontFamily="'Inter-SemiBold', 'Inter Semi Bold', 'Inter', sans-serif"
              fontWeight="700"
            >
              Sign in
            </Typography>
            <MISTextField
              autoComplete="username"
              autoFocus
              color="primary"
              fullWidth
              id="username"
              label="User ID"
              margin="normal"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
              required
              value={username}
              variant="outlined"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '8px',
                width: '100%',
              }}
            >
              <MISButton onClick={handleLogin}>Next</MISButton>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default InitialUsernamePage
